import React from 'react';

function LoadingBox() {
	return (
		<div className="md:flex gap-x-8 mx-auto justify-center items-center p-2">
			<div class="w-full sm:w-[20rem]  animate-pulse">
				<div class="w-full h-64 bg-gray-300 rounded-lg md:h-72 dark:bg-gray-600"></div>
			</div>

			<div class="w-full max-w-lg  animate-pulse py-9">
				<h1 class="h-2 bg-gray-300 rounded-lg w-52 dark:bg-gray-600"></h1>
				<h1 class="w-56 h-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"></h1>
				<p class="w-24 h-2 my-4 bg-gray-200 rounded-lg dark:bg-gray-700"></p>

				<p class="w-48 h-2 mt-6 bg-gray-200 rounded-lg dark:bg-gray-700"></p>
				<p class="w-full h-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"></p>
				<p class="w-64 h-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"></p>
				<p class="w-4/5 h-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"></p>
			</div>
		</div>
	);
}

export default LoadingBox;
