import {
	createBrowserRouter,
	RouterProvider,
	Outlet,
	createRoutesFromElements,
	Route,
	ScrollRestoration,
} from 'react-router-dom';
import AdminRoute from './components/AdminRoute';
import DashboardLayout from './components/DashboardLayout';
import Footer from './components/home/Footer/Footer';
import Header from './components/home/Header/Header';
import HeaderBottom from './components/home/Header/HeaderBottom';
import PrivateRoute from './components/PrivateRoute';
import SellerRoute from './components/SellerRoute';
import SpecialCase from './components/SpecialCase/SpecialCase';
import About from './pages/About/About';
import SignIn from './pages/Account/SignIn';
import SignUp from './pages/Account/SignUp';
import VendorRegister from './pages/Account/VendorRegister';
import Cart from './pages/Cart/Cart';
import Contact from './pages/Contact/Contact';
import CorporateInfo from './pages/CorporateInfo';
import Analytics from './pages/DashboardScreen/Analytics';
import OrderHistory from './pages/DashboardScreen/OrderHistory';
import OrderList from './pages/DashboardScreen/OrderList';
import ProductEdit from './pages/DashboardScreen/ProductEdit';
import ProductList from './pages/DashboardScreen/ProductList';

import Profile from './pages/DashboardScreen/Profile';
import Settings from './pages/DashboardScreen/Settings';
import UserEdit from './pages/DashboardScreen/UserEdit';
import UserList from './pages/DashboardScreen/UserList';
import FAQs from './pages/FAQs';
import Home from './pages/Home/Home';
import Journal from './pages/Journal/Journal';
import Offer from './pages/Offer/Offer';
import OrderDetails from './pages/OrderDetails';
import Payment from './pages/payment/Payment';
import PlaceOrder from './pages/PlaceOrder';
import ProductDetails from './pages/ProductDetails/ProductDetails';
import Seller from './pages/Seller';
import Shipping from './pages/Shipping';
import Shop from './pages/Shop/Shop';
import Terms from './pages/Terms';
import UserDashboard from './pages/UserDashboard';
import Vendor from './pages/Vendor';

const Layout = () => {
	return (
		<div>
			<Header />
			<HeaderBottom />
			<SpecialCase />
			<ScrollRestoration />
			<Outlet />
			<Footer />
		</div>
	);
};
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<Layout />}>
        {/* ==================== Header Navlink Start here =================== */}
        <Route index element={<Home />}></Route>
        <Route path="/shop" element={<Shop />}></Route>
        <Route path="/search" element={<Shop />} exact></Route>
        <Route path="/search/name/:name" element={<Shop />} exact></Route>
        <Route
          path="/search/category/:category"
          element={<Shop />}
          exact
        ></Route>
        <Route
          path="/search/category/:category/name/:name"
          element={<Shop />}
          exact
        ></Route>
        <Route
          path="/search/category/:category/name/:name/min/:min/max/:max/rating/:rating/order/:order/pageNumber/:pageNumber"
          element={<Shop />}
          exact
        ></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="/journal" element={<Journal />}></Route>
        <Route path="faqs" element={<FAQs />} />
        <Route path="terms" element={<Terms />} />
        <Route path="corporateinfo" element={<CorporateInfo />} />
        {/* ==================== Header Navlink End here ===================== */}
        <Route path="/offer" element={<Offer />}></Route>
        {/* <Route path="/product/:_id" element={<ProductDetails />}></Route> */}
        <Route path="/cart" element={<Cart />}></Route>
        <Route path="cart/:id/*" element={<Cart />} />
        <Route path="/paymentgateway" element={<Payment />}></Route>
        <Route path="vendor" element={<Vendor />} />
        <Route path="product/:slug" element={<ProductDetails />} />
        <Route path="placeorder" element={<PlaceOrder />} />
        <Route path="shipping" element={<Shipping />} />

        <Route
          path="userdashboard"
          element={
            // <PrivateRoute>
            <UserDashboard />
            // </PrivateRoute>
          }
        />
      </Route>
      <Route path="/signup" element={<SignUp />}></Route>
      <Route path="/signin" element={<SignIn />}></Route>
      <Route path="/vendorsignup" element={<VendorRegister />}></Route>

      <Route path="account" element={<DashboardLayout />}>
        <Route
          path="profile"
          element={
            <PrivateRoute>
            <Profile />
            </PrivateRoute>
          }
        />
        <Route path="order/:id" element={<OrderDetails />} />
        <Route
          path="overview"
          element={
            // <AdminRoute>
            <Analytics />
            // </AdminRoute>
          }
        />
        <Route path="product/:id/edit" element={<ProductEdit />} />
        <Route path="seller/:id" element={<Seller />}></Route>
        <Route
          path="settings"
          element={
            // <PrivateRoute>
            <Settings />
            // </PrivateRoute>
          }
        />
        <Route path="orderhistory" element={<OrderHistory />} />
        <Route
          path="productlist"
          element={
            // <AdminRoute>
            <ProductList />
            // </AdminRoute>
          }
        />
        <Route
          path="productlist/pageNumber/:pageNumber"
          element={
            // <AdminRoute>
            <ProductList />
            // </AdminRoute>
          }
        />
        <Route
          path="orderlist"
          element={
            // <AdminRoute>
            <OrderList />
            // </AdminRoute>
          }
        />
        <Route
          path="userlist"
          element={
            // <AdminRoute>
            <UserList />
            // </AdminRoute>
          }
        />
        <Route
          path="user/:id/edit"
          element={
            // <AdminRoute>
            <UserEdit />
            // </AdminRoute>
          }
        />

        <Route
          path="productlist/seller"
          element={
            <SellerRoute>
              <ProductList />
            </SellerRoute>
          }
        />
        <Route
          path="orderlist/seller"
          element={
            <SellerRoute>
              <OrderList />
            </SellerRoute>
          }
        />
      </Route>
    </Route>
  )
);

function App() {
	return (
		<div className="font-bodyFont">
			<RouterProvider router={router} />
		</div>
	);
}

export default App;
