import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";

const Contact = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [messages, setMessages] = useState("");

  // ========== Error Messages Start here ============
  const [errfirstName, setErrFirstName] = useState("");
  const [errPhone, setErrPhone] = useState("");
  const [errLastName, setErrLastName] = useState("");
  const [errEmail, setErrEmail] = useState("");
  const [errMessages, setErrMessages] = useState("");
  // ========== Error Messages End here ==============
  const [successMsg, setSuccessMsg] = useState("");

  const handleFirstName = (e) => {
    setFirstName(e.target.value);
    setErrFirstName("");
  };
  const handleLastName = (e) => {
    setLastName(e.target.value);
    setErrLastName("");
  };
  const handlePhone = (e) => {
    setPhone(e.target.value);
    setErrPhone("");
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
    setErrEmail("");
  };
  const handleMessages = (e) => {
    setMessages(e.target.value);
    setErrMessages("");
  };

  // ================= Email Validation start here =============
  const EmailValidation = (email) => {
    return String(email)
      .toLowerCase()
      .match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
  };
  // ================= Email Validation End here ===============

  const handlePost = (e) => {
    e.preventDefault();
    if (!firstName) {
      setErrFirstName("Enter your First Name");
    }
    if (!lastName) {
      setErrLastName("Enter your First Name");
    }
    if (!email) {
      setErrEmail("Enter your Email");
    } else {
      if (!EmailValidation(email)) {
        setErrEmail("Enter a Valid Email");
      }
    }
    if (!phone) {
      setErrPhone("Enter your Password");
    }
    if (!messages) {
      setErrMessages("Enter your Messages");
    }

    const clientName = firstName + "" + lastName;
    if (clientName && email && EmailValidation(email) && messages) {
      setSuccessMsg(
        `Thank you dear ${clientName}, Your messages has been received successfully. Futher details will sent to you by your email at ${email}.`
      );
    }
  };

  return (
    <div className="container mx-auto md:px-4">
      <Breadcrumbs title="Contact" />

      <div class="grid grid-cols-1 mb-10 lg:grid-cols-3 md:grid-cols-2 gap-16">
        <div class="p-4 rounded-sm  md:p-6 shadow-lg">
          <h2 class="text-sm text-[#03ce2f] font-bold md:text-xl">
            Call us Now
          </h2>
          <p class="text-gray-600">+234-805-314-3920</p>
          <h2 class="text-sm text-[#03ce2f] font-bold md:text-xl">Email:</h2>
          <a className="font-bold" href="mailto:business@cobai.africa">
            solutions@cobai.africa
          </a>
        </div>

        <div class="p-4 rounded-sm  md:p-6 shadow-lg">
          <h2 class="text-sm text-[#03ce2f] font-bold md:text-xl">
            Affiliates program or Ads
          </h2>
          <p class="text-gray-600">
            For questions or inquiries regarding our affiliate program or
            Advertisement Packages, please feel free to send us an email{" "}
            <a
              className="font-bold text-black"
              href="mailto:business@cobai.africa"
            >
              business@cobai.africa
            </a>
          </p>
        </div>

        <div class="p-4 rounded-sm  md:p-6 shadow-lg">
          <h2 class="text-sm text-[#03ce2f] font-bold md:text-xl">Careers</h2>
          <p class="text-gray-600">
            For more information about job opportunities with COBAI, For
            questions related to available positions, please contact{" "}
            <a
              className="font-bold text-black"
              href="mailto:business@cobai.africa"
            >
              careers@cobai.africa
            </a>
          </p>
        </div>
      </div>
      {successMsg ? (
        <p className="pb-20 w-96 font-medium text-green-500">{successMsg}</p>
      ) : (
        <>
          <div class="space-y-6 md:space-y-0 md:flex md:gap-6 lg:items-center lg:gap-12">
            <form class="md:7/12 lg:w-6/12">
              <div class="w-full p-4 md:p-8 my-4 md:px-12  rounded-2xl shadow-2xl">
                <div class="">
                  <h2 class="text-sm text-[#03ce2f] font-bold">Get in touch</h2>
                  <h1 class="font-bold text-2xl md:text-3xl">
                    Write us a message
                  </h1>
                </div>
                <div class="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                  <div>
                    <input
                      class="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                      type="text"
                      placeholder="First Name*"
                      required
                      onChange={handleFirstName}
                      value={firstName}
                    />
                    {errfirstName && (
                      <p className="text-red-500 text-[10px] font-titleFont font-semibold mt-1 px-2 flex items-center gap-1">
                        <span className="text-sm italic font-bold">!</span>
                        {errfirstName}
                      </p>
                    )}
                  </div>

                  <div>
                    <input
                      class="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                      type="text"
                      placeholder="Last Name*"
                      required
                      onChange={handleLastName}
                      value={lastName}
                    />
                    {errLastName && (
                      <p className="text-red-500 text-[10px] font-titleFont font-semibold mt-1 px-2 flex items-center gap-1">
                        <span className="text-sm italic font-bold">!</span>
                        {errLastName}
                      </p>
                    )}
                  </div>

                  <div>
                    <input
                      class="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                      type="email"
                      placeholder="Email*"
                      required
                      onChange={handleEmail}
                      value={email}
                    />
                    {errEmail && (
                      <p className="text-red-500 text-[10px] font-titleFont font-semibold mt-1 px-2 flex items-center gap-1">
                        <span className="text-sm italic font-bold">!</span>
                        {errEmail}
                      </p>
                    )}
                  </div>
                  <div>
                    <input
                      class="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                      type="text"
                      placeholder="Phone*"
                      required
                      onChange={handlePhone}
                      value={phone}
                    />
                    {errPhone && (
                      <p className="text-red-500 text-[10px] font-titleFont font-semibold mt-1 px-2 flex items-center gap-1">
                        <span className="text-sm italic font-bold">!</span>
                        {errPhone}
                      </p>
                    )}
                  </div>
                </div>
                <div class="my-4">
                  <textarea
                    placeholder="Message*"
                    required
                    onChange={handleMessages}
                    value={messages}
                    class="w-full h-32 bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                  ></textarea>
                  {errMessages && (
                    <p className="text-red-500 text-[10px] font-titleFont font-semibold mt-1 px-2 flex items-center gap-1">
                      <span className="text-sm italic font-bold">!</span>
                      {errMessages}
                    </p>
                  )}
                </div>
                <div class="my-2 w-[10rem]">
                  <button
                    onClick={handlePost}
                    class="uppercase text-sm font-bold tracking-wide bg-[#03ce2f] text-gray-100 p-3 rounded-full w-full 
                        focus:outline-none focus:shadow-outline"
                  >
                    Send Message
                  </button>
                </div>
              </div>
            </form>

            <div class="md:5/12 lg:w-5/12">
              <img src="img/contact.png" alt="i" />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Contact;
