import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Breadcrumbs from '../../components/pageProps/Breadcrumbs';

const About = () => {
	const location = useLocation();
	// const [prevLocation, setPrevLocation] = useState('');
	// useEffect(() => {
	// 	setPrevLocation(location.state.data);
	// }, [location]);
	return (
		<div className="container m-auto px-2 text-gray-600  md:px-12 xl:px-8">
			<Breadcrumbs title="About" 
			// prevLocation={prevLocation} 
			/>
			<div class="container m-auto  text-gray-600">
				<div class="space-y-6 md:space-y-0 md:flex md:gap-6 lg:items-center lg:gap-12">
					<div class="md:7/12 lg:w-6/12">
						<h2 class="text-2xl text-gray-900 font-bold md:text-4xl">
							Why We Are Unique
						</h2>
						<p class="mt-6 text-gray-600">
							<span className="text-primeColor font-semibold text-lg">
								COBAI
							</span>{' '}
							is Africa’s B2B/B2C classified listing food and agro-allied Market
							Place, that connects Africa businesses in the Agricultural sector,
							giving them an enabling system that helps them close legitimate
							deals and same time makes rock bottom sales both locally and
							internationally.
						</p>
						<Link to="/shop">
							<button className="w-52 h-10 bg-[#03ce2f] text-white hover:bg-black duration-300 rounded-full mt-3">
								Explore Now
							</button>
						</Link>
					</div>

					<div class="md:5/12 lg:w-5/12">
						<img src="img/About.png" alt="i" />
					</div>
				</div>

				<div class="grid grid-cols-1 my-10 lg:grid-cols-3 md:grid-cols-2 gap-5">
					<div class="p-4 rounded-sm  md:p-6 shadow-lg">
						<h2 class="text-base text-[#03ce2f] font-bold md:text-2xl">
							Our Aim
						</h2>
						<p class="text-gray-600">
							A developed, empowered and self-sufficient Africa, through
							aggressive sustainable food security methods, well integrated
							working dynamics that’s seamless for Micro, Small and Medium
							Enterprises [MSMEs] in the food and agro-allied sector.
						</p>
					</div>

					<div class="p-4 rounded-sm  md:p-6 shadow-lg">
						<h2 class="text-base text-[#03ce2f] font-bold md:text-2xl">
							Our Vision
						</h2>
						<p class="text-gray-600">
							COBAI is committed to providing best business practice with more
							ethical standards reaching the final consumers, as our core value
							is <strong>SUSTAINING GREEN CULTURE.</strong>
						</p>
					</div>

					<div class="p-4 rounded-sm  md:p-6 shadow-lg">
						<h2 class="text-base text-[#03ce2f] font-bold md:text-2xl">
							Great Features
						</h2>
						<p class="text-gray-600">
							A platform without class or boundary, where every user is
							empowered by our ROBUST system to maximize profit in a safe and
							reliable way and same time maintaining business ethics.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default About;
