import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { signout } from '../actions/userActions';
import OrderHistory from './DashboardScreen/OrderHistory';
import Profile from './DashboardScreen/Profile';

function UserDashboard() {
	const [openTab, setOpenTab] = useState(1);
	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}, []);

	// const userSignin = useSelector((state) => state.userSignin);
	// const { userInfo } = userSignin;

	const dispatch = useDispatch();
	const signoutHandler = () => {
		dispatch(signout());
	};

	return (
		<main className="section main pages">
			<div className="section page-content pt-150 pb-150">
				<div className="container">
					<div className="row">
						<div className="col-lg-10 m-auto">
							<div className="row">
								<div className="col-md-3">
									<div className="dashboard-menu mb-3">
										<ul className=" flex-column" role="tablist">
											<li className="nav-item">
												<a
													className={
														openTab === 1 ? 'nav-link active' : 'nav-link '
													}
													onClick={() => setOpenTab(1)}
													id="dashboard-tab"
													// data-bs-toggle="tab"
													href="#dashboard"
													// role="tab"
													// aria-controls="dashboard"
													// aria-selected="false"
												>
													<i className="fa fa-sliders mr-2"></i>Dashboard
												</a>
											</li>
											<li className="nav-item">
												<a
													className={
														openTab === 2 ? 'nav-link active' : 'nav-link '
													}
													onClick={() => setOpenTab(2)}
													id="orders-tab"
													// data-bs-toggle="tab"
													href="#orders"
													// role="tab"
													// aria-controls="orders"
													// aria-selected="false"
												>
													<i className="fa fa-shopping-bag mr-2"></i>Orders
												</a>
											</li>
											<li className="nav-item">
												<a
													className={
														openTab === 3 ? 'nav-link active' : 'nav-link '
													}
													id="track-orders-tab"
													onClick={() => setOpenTab(3)}
													// data-bs-toggle="tab"
													href="#track-orders"
													// role="tab"
													// aria-controls="track-orders"
													// aria-selected="false"
												>
													<i className="fa fa-shopping-cart mr-2"></i>Track Your
													Order
												</a>
											</li>

											<li className="nav-item">
												<a
													className={
														openTab === 4 ? 'nav-link active' : 'nav-link '
													}
													id="account-detail-tab"
													onClick={() => setOpenTab(4)}
													// data-bs-toggle="tab"
													href="#account-detail"
													// role="tab"
													// aria-controls="account-detail"
													// aria-selected="true"
												>
													<i className="fa fa-user mr-2"></i>Account details
												</a>
											</li>
											<li className="nav-item ">
												<a
													className={
														openTab === 5 ? 'nav-link active' : 'nav-link '
													}
													onClick={signoutHandler}
													href="##"
												>
													<i className="fa fa-sign-out mr-2"></i>Logout
												</a>
											</li>
										</ul>
									</div>
								</div>
								<div className="col-md-9">
									<div className="tab-content account dashboard-content pl-50">
										<div
											className={
												'tab-pane fade ' + (openTab === 1 ? 'active show' : '')
											}
											id="dashboard"
											role="tabpanel"
											aria-labelledby="dashboard-tab"
										>
											<div className="card">
												<div className="card-header">
													<h3 className="mb-0">Hello 
													{/* {userInfo.name}! */}
													</h3>
												</div>
												<div className="card-body">
													<p>
														From your account dashboard. you can easily check
														&amp; view your{' '}
														<a
															href="#recent orders"
															onClick={() => setOpenTab(2)}
														>
															recent orders
														</a>
														,<br />
														manage your{' '}
														<a
															href="#account-detail"
															onClick={() => setOpenTab(4)}
														>
															account details and edit your password.
														</a>
													</p>
												</div>
											</div>
										</div>
										<div
											className={
												'tab-pane fade ' + (openTab === 2 ? 'active show' : '')
											}
											id="orders"
											role="tabpanel"
											aria-labelledby="orders-tab"
										>
											<div className="card">
												<div className="card-header">
													<h3 className="mb-0">Your Orders</h3>
												</div>
												<div className="card-body">
													<OrderHistory />
												</div>
											</div>
										</div>
										<div
											className={
												'tab-pane fade ' + (openTab === 3 ? 'active show' : '')
											}
											id="track-orders"
											role="tabpanel"
											aria-labelledby="track-orders-tab"
										>
											<div className="card">
												<div className="card-header">
													<h3 className="mb-0">Orders tracking</h3>
												</div>
												<div className="card-body contact-from-area">
													<p>
														To track your order please enter your OrderID in the
														box below and press "Track" button. This was given
														to you on your receipt and in the confirmation email
														you should have received.
													</p>
													<div className="row">
														<div className="col-lg-8">
															<form className="contact-form-style mt-3 mb-5">
																<div className="input-style mb-4">
																	<label>Order ID</label>
																	<input
																		name="order-id"
																		placeholder="Found in your order confirmation email"
																		type="text"
																		className="track_input"
																		required
																	/>
																</div>
																<div className="input-style mb-4">
																	<label>Billing email</label>
																	<input
																		name="billing-email"
																		placeholder="Email you used during checkout"
																		type="email"
																		className="track_input"
																		required
																	/>
																</div>
																<button
																	className="submit submit-auto-width"
																	type="submit"
																>
																	Track
																</button>
															</form>
														</div>
													</div>
												</div>
											</div>
										</div>

										<div
											className={
												'tab-pane fade ' + (openTab === 4 ? 'active show' : '')
											}
										>
											<div className="card">
												<Profile />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
	);
}

export default UserDashboard;
