import React from "react";

function MessageBox(props) {
  return (
    
    <div className={`text-center py-3 ${props.variant || "text-green-600 bg-green-200"}`}>
      {props.children}
    </div>
  );
}

export default MessageBox;
