import React from 'react';
import { Link } from 'react-router-dom';

function Terms() {
	return (
		<div>
			<div className="">
				<div class="container m-auto px-2 text-gray-600 mt-4 lg:mt-10 md:px-12 xl:px-8">
					<div
						class="w-full bg-center bg-cover h-[9rem] lg:h-[15rem]  rounded-2xl"
						style={{ 'background-image': 'url(img/header-bg.png)' }}
					>
						<div class="flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50 rounded-2xl">
							<div class="text-center">
								<h1 class="text-2xl font-extrabold text-white py-3 lg:text-5xl">
									TERMS AND CONDITIONS
								</h1>
							</div>
						</div>
					</div>
				</div>

				<div className=" container max-w-6xl  border-inherit rounded-xl  mx-auto my-10 px-2 py-6 lg:px-10  w-auto">
					<div className="w-full md:w-5/5 mx-auto p-2">
						<h3 className="text-xl font-bold text-gray-800">INTRODUCTION</h3>

						<div className="lg:flex  my-1">
							<p className="text-sm font-normal text-gray-600">
								<div className="mb-5">
									Welcome to the ‘COBAI’ Africa’s B2B/B2C Food and Agro-allied
									classified listing Market Place. These terms and conditions
									("Terms and Conditions") apply to the Site, which reference
									these Terms and Conditions. This website is owned and operated
									by Cobai Global Resources Nigeria Limited.
								</div>
								<div>
									‘COBAI’ reserves the right to contents on our Site. Some of
									the contents listed on the Site are owned by ‘COBAI’
									despite‘COBAI’is involved in the actual sale transaction
									between the buyers and sellers on its Site. For the purpose of
									this website “we”, us” and “our” all refer to ‘COBAI’.  By
									accessing the Site, you confirm your understanding of the
									Terms and Conditions. If you do not agree to these Terms and
									Conditions of use, you shall not use this website. The Site
									reserves the right to change, modify, add, or remove portions
									of these Terms and Conditions of use at any time. Changes will
									be effective when posted on the Site with no other notice
									provided. Please check these Terms and Conditions of use
									regularly for updates. Your continued use of the Site
									following the posting of changes to these Terms and Conditions
									of use constitutes your acceptance of those changes.
								</div>
							</p>

							<img className="lg:w-[20rem]" src="img/terms.png" alt="" />
						</div>

						<div className="shadow-md">
							<div className="tab w-full overflow-hidden border-t">
								<input
									className="absolute opacity-0 "
									id="tab-multi-one"
									type="checkbox"
									name="tabs"
								/>
								<label
									className="block bg-white p-5 leading-normal cursor-pointer uppercase"
									htmlFor="tab-multi-one"
								>
									1. USE OF THE SITE
								</label>
								<div className="tab-content overflow-hidden border-l-2 bg-gray-100 border-[#03ce2f] leading-normal">
									<p className="px-5 pt-5">
										You confirm that you are at least 18 years of age or are
										accessing the Site under the supervision of a parent or
										legal guardian. We grant you a non-transferable, revocable
										and non-exclusive license to use the Site, in accordance
										with the Terms and Conditions described herein, for the
										purpose of shopping for personal items sold on the Site.
										Commercial use or use on behalf of any third party is
										prohibited, except as explicitly permitted by us in advance.{' '}
										<br /> Any breach of these Terms and Conditions shall result
										in the immediate revocation of the license granted in this
										paragraph without notice to you. Content provided on this
										site is solely for informational purposes. Product
										representations expressed on this Site are those of the
										vendors and are not made by us. Submissions or opinions
										expressed on this Site are those of the individual posting
										such content and may not reflect our opinions. Certain
										services and related features that may be made available on
										the Site may require registration or subscription. Should
										you choose to register or subscribe for any such services or
										related features; you agree to provide accurate and current
										information about yourself, and to promptly update such
										information if there are any changes. Every user of the Site
										is solely responsible for keeping passwords and other
										account identifiers safe and secure. The account owner is
										entirely responsible for all activities that occur under
										such password or account. <br /> Furthermore, you must
										notify us of any unauthorized use of your password or
										account. The Site shall not be responsible or liable,
										directly or indirectly, in any way for any loss or damage of
										any kind incurred as a result of, or in connection with,
										your failure to comply with this section. During the
										registration process you agree to receive promotional emails
										from the Site. You can subsequently opt out of receiving
										such promotional e-mails by clicking on the link at the
										bottom of any promotional email. 
									</p>
								</div>
							</div>
							<div className="tab w-full overflow-hidden border-t">
								<input
									className="absolute opacity-0"
									id="tab-multi-two"
									type="checkbox"
									name="tabs"
								/>
								<label
									className="block bg-white p-5 leading-normal cursor-pointer uppercase"
									htmlFor="tab-multi-two"
								>
									2. ACCESSIBILITY OF WEBSITE
								</label>
								<div className="tab-content overflow-hidden border-l-2 bg-gray-100 border-[#03ce2f] leading-normal">
									<p className="px-5 py-3">
										Our aim is to ensure accessibility to the website at all
										times, however, we make to representations of that nature
										and reserve the right to terminate the website at any time
										and without notice. You accept that service interruption may
										occur in order to allow for website improvements, scheduled
										maintenance or may also be due to outside factors beyond our
										control. 
									</p>
								</div>
							</div>
							<div className="tab w-full overflow-hidden border-t">
								<input
									className="absolute opacity-0"
									id="tab-multi-three"
									type="checkbox"
									name="tabs"
								/>
								<label
									className="block bg-white p-5 leading-normal cursor-pointer uppercase"
									htmlFor="tab-multi-three"
								>
									3. VIOLATION
								</label>
								<div className="tab-content overflow-hidden border-l-2 bg-gray-100 border-[#03ce2f] leading-normal">
									<p className="px-5 py-3">
										<strong>‘COBAI’</strong> and the community work together to
										keep our sites and services working properly and the
										community safe. Please report problems, offensive content
										and policy breaches to us. We work to ensure that listed
										items do not infringe upon the copyright, trademark or other
										intellectual property rights of third parties. If you
										believe that your intellectual property rights have been
										infringed, please notify our team and we will investigate.
									</p>
								</div>
							</div>
							<div className="tab w-full overflow-hidden border-t">
								<input
									className="absolute opacity-0"
									id="tab-multi-four"
									type="checkbox"
									name="tabs"
								/>
								<label
									className="block bg-white p-5 leading-normal cursor-pointer uppercase"
									htmlFor="tab-multi-three"
								>
									4. CONTENT SUBMISSIONS
								</label>
								<div className="tab-content overflow-hidden border-l-2 bg-gray-100 border-[#03ce2f] leading-normal">
									<p className="px-5 py-3">
										Anything that you submit to the Site and/or provide to us,
										including but not limited to, questions, reviews, comments,
										and suggestions (collectively, "Submissions") will become
										our sole and exclusive property and shall not be returned to
										you. In addition to the rights applicable to any Submission,
										when you post comments or reviews to the Site, you also
										grant us the right to use the name that you submit, in
										connection with such review, comment, or other content. You
										shall not use a false e-mail address, pretend to be someone
										other than you or otherwise mislead us or third parties as
										to the origin of any Submissions.
										<br />
										<strong>‘COBAI’</strong> may, but shall not be obligated to,
										remove or edit any Submissions..
									</p>
								</div>
							</div>

							<div className="tab w-full overflow-hidden border-t">
								<input
									className="absolute opacity-0"
									id="tab-multi-five"
									type="checkbox"
									name="tabs"
								/>
								<label
									className="block bg-white p-5 leading-normal cursor-pointer uppercase"
									htmlFor="tab-multi-three"
								>
									5. TRADEMARKS AND COPYRIGHTS
								</label>
								<div className="tab-content overflow-hidden border-l-2 bg-gray-100 border-[#03ce2f] leading-normal">
									<p className="px-5 py-3">
										All intellectual property rights, whether registered or
										unregistered, in the Site, information content on the Site
										and all the website design, including, but not limited to,
										text, graphics, software, photos, video, music, sound, and
										their selection and arrangement, and all software
										compilations, underlying source code and software shall
										remain our property. The entire contents of the Site also
										are protected by copyright as a collective work under
										Nigeria copyright laws and international conventions. All
										rights are reserved.
									</p>
								</div>
							</div>
							<div className="tab w-full overflow-hidden border-t">
								<input
									className="absolute opacity-0"
									id="tab-multi-#"
									type="checkbox"
									name="tabs"
								/>
								<label
									className="block bg-white p-5 leading-normal cursor-pointer uppercase"
									htmlFor="tab-multi-three"
								>
									6. LEGAL DISPUTES
								</label>
								<div className="tab-content overflow-hidden border-l-2 bg-gray-100 border-[#03ce2f] leading-normal">
									<p className="p-5">
										If a dispute arises between you and ‘COBAI’, our goal is to
										provide you with a neutral and cost effective means of
										resolving the dispute quickly. Accordingly, you and
										‘COBAI’agrees that we will resolve any claim or controversy
										at law or equity that arises out of this Agreement or our
										services in accordance with one of the subsections below or
										as we and you otherwise agree in writing. Before resorting
										to these alternatives, we strongly encourage you to first
										contact us directly to seek a resolution. We will consider
										reasonable requests to resolve the dispute through
										alternative dispute resolution procedures, such as
										arbitration, as alternatives to litigation.  
									</p>
									<p className="px-5">
										<strong>LIMITATION OF LIABILITY</strong> <br />
										The buyer and seller both agree that ‘COBAI’ would be held
										free from any liability in contract, pre-contract or other
										representations, in tort, including, without limitation,
										negligence, or otherwise out of or in connection with the
										terms and conditions for any economic losses, such as, but
										not limited to profits, anticipated savings, revenues,
										contracts or general business; loss of goodwill or
										reputation; and any special or indirect losses suffered or
										incurred by any of the parties arising out of or in
										connection with the provisions of any matter under these
										terms and conditions. The buyer and the seller accept that
										‘COBAI’ will not be responsible or liable for any loss of
										content or materials uploaded or transmitted through the
										website and accept no liability of any kind for any loss or
										damage from action taken or taken in reliance on material or
										information contained on the site. The Site and the services
										are provided "as is, where is" and as and when available,
										and to the extent permissible by law all implied warranties,
										conditions or other terms, whether implied by statute or
										otherwise, including without limitation, any terms as to
										skill and care or timeliness of performance, are excluded.
									</p>
									<p className="px-5 py-3">
										<strong>APPLICABLE LAW AND JURISDICTION</strong> <br />
										These Terms and Conditions shall be interpreted and governed
										by the laws in force in the Federal Republic of Nigeria.
										Subject to the Arbitration section below, each party hereby
										agrees to submit to the jurisdiction of the courts of
										Nigeria and to waive any objections based upon venue.
									</p>
								</div>
							</div>

							<div className="tab w-full overflow-hidden border-t">
								<input
									className="absolute opacity-0"
									id="tab-multi-three"
									type="checkbox"
									name="tabs"
								/>
								<label
									className="block bg-white p-5 leading-normal cursor-pointer uppercase"
									htmlFor="tab-multi-three"
								>
									7. ARBITRATION
								</label>
								<div className="tab-content overflow-hidden border-l-2 bg-gray-100 border-[#03ce2f] leading-normal">
									<p className="p-5">
										Any controversy, claim or dispute arising out of or relating
										to these Terms and Conditions will be referred to and
										finally settled by private and confidential binding
										arbitration before a single arbitrator held in Nigeria in
										English and governed by Nigerian law pursuant to the
										Arbitration and Conciliation Act Cap A18 Laws of the
										Federation of Nigeria 2004, as amended, replaced or
										re-enacted from time to time. The arbitrator shall be a
										person who is legally trained and who has experience in the
										information technology field in Nigeria and is independent
										of either party. Notwithstanding the foregoing, the Site
										reserves the right to pursue the protection of intellectual
										property rights and confidential information through
										injunctive or other equitable relief through the courts.
									</p>
								</div>
							</div>
							<div className="tab w-full overflow-hidden border-t">
								<input
									className="absolute opacity-0"
									id="tab-multi-three"
									type="checkbox"
									name="tabs"
								/>
								<label
									className="block bg-white p-5 leading-normal cursor-pointer uppercase"
									htmlFor="tab-multi-three"
								>
									8. PRIVACY AND CONFIDENTIALITY
								</label>
								<div className="tab-content overflow-hidden border-l-2 bg-gray-100 border-[#03ce2f] leading-normal">
									<p className="p-5">
										We respect your privacy and want to protect your personal
										information. To learn more, please read this Privacy Policy.
										This Privacy Policy explains how we collect, use and (under
										certain conditions) disclose your personal information. This
										Privacy Policy also explains the steps we have taken to
										secure your personal information. Finally, this Privacy
										Policy explains your options regarding the collection, use
										and disclosure of your personal information. By visiting the
										Site directly or through another site, you accept the
										practices described in this Policy. Data protection is a
										matter of trust and your privacy is important to us. We
										shall therefore only use your name and other information
										which relates to you in the manner set out in this Privacy
										Policy. We will only collect information where it is
										necessary for us to do so and we will only collect
										information if it is relevant to our dealings with you. We
										will only keep your information for as long as we are either
										required to by law or as is relevant for the purposes for
										which it was collected. You can visit the Site and browse
										without having to provide personal details. During your
										visit to the Site you remain anonymous and at no time can we
										identify you unless you have an account on the Site and log
										on with your user name and password.
									</p>
									<p className="px-5">
										We collect, store and process your data for processing your
										purchase on the Site and any possible later claims, and to
										provide you with our services. We may collect personal
										information including, but not limited to, your title, name,
										gender, date of birth, email address, postal address,
										delivery address (if different), telephone number, mobile
										number, fax number, payment details, payment card details or
										bank account details.
									</p>
									<p className="px-5 py-3">
										We will use the information you provide to enable us to
										process your orders and to provide you with the services and
										information offered through our website and which you
										request. Further, we will use the information you provide to
										administer your account with us; verify and carry out
										financial transactions in relation to payments you MAKE
										ONLINE; audit the downloading of data from our website;
										improve the layout and/or content of the pages of our
										website and customize them for users; identify visitors on
										our website; carry out research on our users' demographics;
										send you information we think you may find useful or which
										you have requested from us, including information about our
										products and services, provided you have indicated that you
										have not objected to being contacted for these purposes.
										Subject to obtaining your consent we may contact you by
										email with details of other products and services. If you
										prefer not to receive any marketing communications from us,
										you can opt out at any time. 
									</p>
									<p className="px-5">
										We may pass your name and address on to a third party in
										order to make delivery of the product to you (for example to
										our courier or supplier). <br />
										Payments that are made through the Site will be processed by
										our relevant agent. You must only submit to us or our Agent
										or the Site information that is accurate and not misleading
										and you must keep it up to date and inform us of changes.
										Your actual order details may be stored with us but for
										security reasons cannot be retrieved directly by us.
										However, you may access this information by logging into
										your account on the Site. Here you can view the details of
										your orders that have been completed, those that are open
										and those that are shortly to be dispatched and administer
										your address details, bank details and any newsletter to
										which you may have subscribed. You undertake to treat the
										personal access data confidentially and not make it
										available to unauthorized third parties. We cannot assume
										any liability for misuse of passwords unless this misuse is
										our fault.  <br />
										If you choose to provide us with personal information, you
										consent to the transfer and storage of that information on
										our servers.
									</p>
								</div>
							</div>
							<div className="tab w-full overflow-hidden border-t">
								<input
									className="absolute opacity-0"
									id="tab-multi-three"
									type="checkbox"
									name="tabs"
								/>
								<label
									className="block bg-white p-5 leading-normal cursor-pointer uppercase"
									htmlFor="tab-multi-three"
								>
									9. IMPORTANCE OF YOUR INFORMATION
								</label>
								<div className="tab-content overflow-hidden border-l-2 bg-gray-100 border-[#03ce2f] leading-normal">
									<p className="px-5 py-3">
										We may use your personal information for opinion and market
										research. Your details are anonymous and will only be used
										for statistical purposes. You can choose to opt out of this
										at any time. Any answers to surveys or opinion polls we may
										ask you to complete will not be forwarded on to third
										parties. Disclosing your email address is only necessary if
										you would like to take part in competitions. We save the
										answers to our surveys separately from your email address. 
										<br />
										We may also send you other information about us, the Site,
										our other websites, our products, sales promotions, our
										newsletters, anything relating to other companies in our
										group or our business partners. If you would prefer not to
										receive any of this additional information as detailed in
										this paragraph (or any part of it) please click the
										'unsubscribe' link in any email that we send to you. Within
										7 working days of receipt of your instruction [Exemption of
										Saturday and Sunday] we will cease to send you information
										as requested. If your instruction is unclear we will contact
										you for clarification. 
										<br />
										We may further anonymize data about users of the Site
										generally and use it for various purposes, including
										ascertaining the general location of the users and usage of
										certain aspects of the Site or a link contained in an email
										to those registered to receive them, and supplying that
										anonymized data to third parties such as publishers.
										However, that anonymized data will not be capable of
										identifying you personally.
									</p>
								</div>
							</div>
							<div className="tab w-full overflow-hidden border-t">
								<input
									className="absolute opacity-0"
									id="tab-multi-three"
									type="checkbox"
									name="tabs"
								/>
								<label
									className="block bg-white p-5 leading-normal cursor-pointer uppercase"
									htmlFor="tab-multi-three"
								>
									10. How do I take advantage of COBAI?
								</label>
								<div className="tab-content overflow-hidden border-l-2 bg-gray-100 border-[#03ce2f] leading-normal">
									<p className="px-5 py-3">
										We act as direct facilitators and Escrow of every deal to
										avoid fraud and we charge 10% of every successfully closed
										deal.
									</p>
								</div>
							</div>
							<div className="tab w-full overflow-hidden border-t">
								<input
									className="absolute opacity-0"
									id="tab-multi-three"
									type="checkbox"
									name="tabs"
								/>
								<label
									className="block bg-white p-5 leading-normal cursor-pointer uppercase"
									htmlFor="tab-multi-three"
								>
									11. IMPORTANCE OF YOUR INFORMATION
								</label>
								<div className="tab-content overflow-hidden border-l-2 bg-gray-100 border-[#03ce2f] leading-normal">
									<p className="p-5">
										We may use your personal information for opinion and market
										research. Your details are anonymous and will only be used
										for statistical purposes. You can choose to opt out of this
										at any time. Any answers to surveys or opinion polls we may
										ask you to complete will not be forwarded on to third
										parties. Disclosing your email address is only necessary if
										you would like to take part in competitions. We save the
										answers to our surveys separately from your email address.
									</p>
									<p className="px-5">
										We may also send you other information about us, the Site,
										our other websites, our products, sales promotions, our
										newsletters, anything relating to other companies in our
										group or our business partners. If you would prefer not to
										receive any of this additional information as detailed in
										this paragraph (or any part of it) please click the
										'unsubscribe' link in any email that we send to you. Within
										7 working days of receipt of your instruction [Exemption of
										Saturday and Sunday] we will cease to send you information
										as requested. If your instruction is unclear we will contact
										you for clarification.
									</p>
									<p className="px-5 py-3">
										We may further anonymize data about users of the Site
										generally and use it for various purposes, including
										ascertaining the general location of the users and usage of
										certain aspects of the Site or a link contained in an email
										to those registered to receive them, and supplying that
										anonymized data to third parties such as publishers.
										However, that anonymized data will not be capable of
										identifying you personally.
									</p>
								</div>
							</div>
							<div className="tab w-full overflow-hidden border-t">
								<input
									className="absolute opacity-0"
									id="tab-multi-three"
									type="checkbox"
									name="tabs"
								/>
								<label
									className="block bg-white p-5 leading-normal cursor-pointer uppercase"
									htmlFor="tab-multi-three"
								>
									12. OUR DISCLOSURE OF YOUR INFORMATION
								</label>
								<div className="tab-content overflow-hidden border-l-2 bg-gray-100 border-[#03ce2f] leading-normal">
									<p className="px-5 py-3">
										We may disclose personal information to respond to legal
										requirements, enforce our policies, respond to claims that a
										listing or other content violates the rights of others, or
										protect anyone’s rights, property or safety. Such
										information will be disclosed in accordance with applicable
										laws and regulations. We may also share your personal
										information:
									</p>
									<p className="px-5 pb-3">
										1. Third parties to provide joint content and services (like
										registration, transactions and customer support), to help
										detect and prevent potentially illegal acts and violations
										of our policies, and to guide decisions about their
										products, services and communications. These third parties
										will use this information to send you marketing
										communications only if you’ve requested their services.
									</p>
									<p className="px-5 pb-3">
										2. Service providers under contract who help with our
										business operations (such as fraud investigations, bill
										collection, affiliate and rewards programs and customer
										support).
									</p>
									<p className="px-5 pb-3">
										3. Other third parties to whom you explicitly ask us to send
										your information (or about whom you are otherwise explicitly
										notified and consent to when using a specific service).
									</p>
									<p className="px-5 pb-3">
										4. Law enforcement agencies, other governmental agencies or
										third parties in response to a request for information
										relating to a criminal investigation, alleged illegal
										activity or any other activity that may expose us, you or
										any other ‘COBAI’ user to legal liability. The personal
										information we disclose may include your User ID and User ID
										history, name, city, county, telephone number, email
										address, fraud complaints and listing history or anything
										else that we deem relevant.
									</p>
									<p className="px-5 pb-3">
										5. Other agencies in connection with an investigation of
										fraud, intellectual property infringement, piracy, or other
										unlawful activity, as we in our sole discretion believe
										necessary or appropriate, either under confidentiality
										agreement or as required by law. In such circumstances, we
										may disclose name, street address, city, county, postcode,
										country, phone number, email address and company name.
									</p>
									<p className="px-5 pb-3">
										6. Other business entities, should we plan to merge with or
										be acquired by that business entity. (Should such a
										combination occur, we will require that the new combined
										entity follow this privacy policy with respect to your
										personal information. If your personal information will be
										used contrary to this policy, you will receive prior
										notice).
									</p>
								</div>
							</div>
							<div className="tab w-full overflow-hidden border-t">
								<input
									className="absolute opacity-0"
									id="tab-multi-three"
									type="checkbox"
									name="tabs"
								/>
								<label
									className="block bg-white p-5 leading-normal cursor-pointer uppercase"
									htmlFor="tab-multi-three"
								>
									13. COMPETITIONS
								</label>
								<div className="tab-content overflow-hidden border-l-2 bg-gray-100 border-[#03ce2f] leading-normal">
									<p className="p-5">
										For any competition we use the data to notify winners and
										advertise our offers. You can find more details where
										applicable in our participation terms for the respective
										competition.
									</p>
								</div>
							</div>
							<div className="tab w-full overflow-hidden border-t">
								<input
									className="absolute opacity-0"
									id="tab-multi-three"
									type="checkbox"
									name="tabs"
								/>
								<label
									className="block bg-white p-5 leading-normal cursor-pointer uppercase"
									htmlFor="tab-multi-three"
								>
									14. THIRD PARTIES AND LINKS
								</label>
								<div className="tab-content overflow-hidden border-l-2 bg-gray-100 border-[#03ce2f] leading-normal">
									<p className="p-5">
										We may pass your details to other companies in our group. We
										may also pass your details to our agents and subcontractors
										to help us with any of our uses of your data set out in our
										Privacy Policy. For example, we may use third parties to
										assist us with delivering products to you, to help us to
										collect payments from you, to analyze data and to provide us
										with marketing or customer service assistance. We may
										exchange information with third parties for the purposes of
										fraud protection and credit risk reduction. We may transfer
										our databases containing your personal information if we
										sell our business or part of it. Other than as set out in
										this Privacy Policy, we shall NOT sell or disclose your
										personal data to third parties without obtaining your prior
										consent unless this is necessary for the purposes set out in
										this Privacy Policy or unless we are required to do so by
										law. The Site may contain advertising of third parties and
										links to other sites or frames of other sites. Please be
										aware that we are not responsible for the privacy practices
										or content of those third parties or other sites, nor for
										any third party to whom we transfer your data in accordance
										with our Privacy Policy.
									</p>
								</div>
							</div>
							<div className="tab w-full overflow-hidden border-t">
								<input
									className="absolute opacity-0"
									id="tab-multi-three"
									type="checkbox"
									name="tabs"
								/>
								<label
									className="block bg-white p-5 leading-normal cursor-pointer uppercase"
									htmlFor="tab-multi-three"
								>
									15. NO SPAM, SPYWARE OR SPOOFING
								</label>
								<div className="tab-content overflow-hidden border-l-2 bg-gray-100 border-[#03ce2f] leading-normal">
									<p className="p-5">
										We and our users do not tolerate spam. Make sure to set your
										‘COBAI’communication preferences so we communicate to you as
										you prefer. You are not licensed to contact other
										‘COBAI’users without their express consent. To report
										‘COBAI’ -related spam or spoof emails to ‘COBAI’ please
										report it to us. You may not use our communication tools to
										send spam or otherwise send content that would breach our
										User Agreement.
									</p>
								</div>
							</div>
							<div className="tab w-full overflow-hidden border-t">
								<input
									className="absolute opacity-0"
									id="tab-multi-three"
									type="checkbox"
									name="tabs"
								/>
								<label
									className="block bg-white p-5 leading-normal cursor-pointer uppercase"
									htmlFor="tab-multi-three"
								>
									16. ACCOUNT PROTECTION
								</label>
								<div className="tab-content overflow-hidden border-l-2 bg-gray-100 border-[#03ce2f] leading-normal">
									<p className="p-5">
										Your password is the key to your account. Use unique
										numbers, letters and special characters, and do not disclose
										your ‘COBAI’password to anyone. If you do share your
										password or your personal information with others, remember
										that you are responsible for all actions taken in the name
										of your account. If you lose control of your password, you
										may lose substantial control over your personal information
										and may be subject to legally binding actions taken on your
										behalf. Therefore, if your password has been compromised for
										any reason, you should immediately notify ‘COBAI’and change
										your password.
									</p>
								</div>
							</div>
							<div className="tab w-full overflow-hidden border-t">
								<input
									className="absolute opacity-0"
									id="tab-multi-three"
									type="checkbox"
									name="tabs"
								/>
								<label
									className="block bg-white p-5 leading-normal cursor-pointer uppercase"
									htmlFor="tab-multi-three"
								>
									17. TRANSACTION PROTECTION
								</label>
								<div className="tab-content overflow-hidden border-l-2 bg-gray-100 border-[#03ce2f] leading-normal">
									<p className="p-5">
										‘COBAI’ reserves the right to adhere strictly to Safe and
										Secured Transactions on Her Platform deeming it necessarily
										to act as <strong>ESCROW</strong> for both{' '}
										<strong>SELLERS/BUYERS </strong> on Platform i.e. we are
										solely in charge of all Transactions Payments. We charge{' '}
										<strong>10% [TEN PERCENT]</strong> as our Transaction fee
										for every successful Transaction closed on Platform.
									</p>
								</div>
							</div>
							<div className="tab w-full overflow-hidden border-t">
								<input
									className="absolute opacity-0"
									id="tab-multi-three"
									type="checkbox"
									name="tabs"
								/>
								<label
									className="block bg-white p-5 leading-normal cursor-pointer uppercase"
									htmlFor="tab-multi-three"
								>
									18. SECURITY
								</label>
								<div className="tab-content overflow-hidden border-l-2 bg-gray-100 border-[#03ce2f] leading-normal">
									<p className="px-5 py-3">
										We have in place appropriate technical and security measures
										to prevent unauthorized or unlawful access to or accidental
										loss of or destruction or damage to your information. When
										we collect data through the Site, we collect your personal
										details on a secure server. We use firewalls on our servers.
										When we collect payment card details electronically, we use
										encryption by using Secure Socket Layer (SSL) coding. While
										we are unable to guarantee 100% security, this makes it hard
										for a hacker to decrypt your details. You are strongly
										recommended not to send full credit or debit card details in
										unencrypted electronic communications with us. We maintain
										physical, electronic and procedural safeguards in connection
										with the collection, storage and disclosure of your
										information. Our security procedures mean that we may
										occasionally request proof of identity before we disclose
										personal information to you. You are responsible for
										protecting against unauthorized access to your password and
										to your computer.
									</p>
								</div>
							</div>
							<div className="tab w-full overflow-hidden border-t">
								<input
									className="absolute opacity-0"
									id="tab-multi-three"
									type="checkbox"
									name="tabs"
								/>
								<label
									className="block bg-white p-5 leading-normal cursor-pointer uppercase"
									htmlFor="tab-multi-three"
								>
									19. COOKIES
								</label>
								<div className="tab-content overflow-hidden border-l-2 bg-gray-100 border-[#03ce2f] leading-normal">
									<p className="p-5">
										The acceptance of cookies is not a requirement for visiting
										the Site. However we would like to point out that the use of
										the 'basket' functionality on the Site and ordering is only
										possible with the activation of cookies. Cookies are tiny
										text files which identify your computer to our server as a
										unique user when you visit certain pages on the Site and
										they are stored by your Internet browser on your computer's
										hard drive. Cookies can be used to recognize your Internet
										Protocol address, saving you time while you are on, or want
										to enter, the Site. We only use cookies for your convenience
										in using the Site (for example to remember who you are when
										you want to amend your shopping cart without having to
										re-enter your email address) and not for obtaining or using
										any other information about you (for example targeted
										advertising). Your browser can be set to not accept cookies,
										but this would restrict your use of the Site. Please accept
										our assurance that our use of cookies does not contain any
										personal or private details and are free from viruses. If
										you want to find out more information about cookies, go to
										<a href="http://www.allaboutcookies.org">allaboutcookies</a>
										or to find out about removing them from your browser, go to
										<a href="http://www.allaboutcookies.org/manage-cookies/index.html">
											allaboutcookies.org/manage-cookies
										</a>
									</p>
									<p className='px-5 py-3'>
										This website uses Google Analytics, a web analytics service
										provided by Google, Inc. ("Google"). Google Analytics uses
										cookies, which are text files placed on your computer, to
										help the website analyze how users use the site. The
										information generated by the cookie about your use of the
										website (including your IP address) will be transmitted to
										and stored by Google on servers in the United States. Google
										will use this information for the purpose of evaluating your
										use of the website, compiling reports on website activity
										for website operators and providing other services relating
										to website activity and internet usage. Google may also
										transfer this information to third parties where required to
										do so by law, or where such third parties process the
										information on Google's behalf. Google will not associate
										your IP address with any other data held by Google. You may
										refuse the use of cookies by selecting the appropriate
										settings on your browser, however please note that if you do
										this you may not be able to use the full functionality of
										this website. By using this website, you consent to the
										processing of data about you by Google in the manner and for
										the purposes set out above.
									</p>
								</div>
							</div>
							<div className="tab w-full overflow-hidden border-t">
								<input
									className="absolute opacity-0"
									id="tab-multi-three"
									type="checkbox"
									name="tabs"
								/>
								<label
									className="block bg-white p-5 leading-normal cursor-pointer uppercase"
									htmlFor="tab-multi-three"
								>
									20. YOUR RIGHTS
								</label>
								<div className="tab-content overflow-hidden border-l-2 bg-gray-100 border-[#03ce2f] leading-normal">
									<p className="px-5 py-3">
										If you are concerned about your data you have the right to
										request access to the personal data which we may hold or
										process about you. You have the right to require us to
										correct any inaccuracies in your data free of charge. At any
										stage you also have the right to ask us to stop using your
										personal data for direct marketing purposes.
									</p>
								</div>
							</div>
							<div className="tab w-full overflow-hidden border-t">
								<input
									className="absolute opacity-0"
									id="tab-multi-three"
									type="checkbox"
									name="tabs"
								/>
								<label
									className="block bg-white p-5 leading-normal cursor-pointer uppercase"
									htmlFor="tab-multi-three"
								>
									21. GENERAL
								</label>
								<div className="tab-content overflow-hidden border-l-2 bg-gray-100 border-[#03ce2f] leading-normal">
									<p className="p-5">
										We may amend this Privacy Policy at any time by posting the
										amended terms on this site.
									</p>
								</div>
							</div>
							<div className="tab w-full overflow-hidden border-t">
								<input
									className="absolute opacity-0"
									id="tab-multi-three"
									type="checkbox"
									name="tabs"
								/>
								<label
									className="block bg-white p-5 leading-normal cursor-pointer uppercase"
									htmlFor="tab-multi-three"
								>
									22. INDEMNITY
								</label>
								<div className="tab-content overflow-hidden border-l-2 bg-gray-100 border-[#03ce2f] leading-normal">
									<p className="px-5 py-3">
										You will indemnify and hold us (and our officers, directors,
										agents, subsidiaries, joint ventures and employees) harmless
										from any claim or demand, including reasonable attorneys’
										fees, made by any third party due to or arising out of your
										breach of this Agreement, or your violation of any law or
										the rights of a third party.
									</p>
								</div>
							</div>
							<div className="tab w-full overflow-hidden border-t">
								<input
									className="absolute opacity-0"
									id="tab-multi-three"
									type="checkbox"
									name="tabs"
								/>
								<label
									className="block bg-white p-5 leading-normal cursor-pointer uppercase"
									htmlFor="tab-multi-three"
								>
									23. NOTICES
								</label>
								<div className="tab-content overflow-hidden border-l-2 bg-gray-100 border-[#03ce2f] leading-normal">
									<p className="px-5 py-3">
										We shall send notices to you by email to the email address
										you provide to ‘COBAI’ during the registration process.
										Notice shall be deemed given 24 hours after email is sent,
										unless the sending party is notified that the email address
										is invalid. Alternatively, we may give you legal notice by
										registered mail to the address provided during the
										registration process. Notices sent to you by registered mail
										shall be deemed to have been received by that party three
										days after the date of mailing.
									</p>
								</div>
							</div>
							<div className="tab w-full overflow-hidden border-t">
								<input
									className="absolute opacity-0"
									id="tab-multi-three"
									type="checkbox"
									name="tabs"
								/>
								<label
									className="block bg-white p-5 leading-normal cursor-pointer uppercase"
									htmlFor="tab-multi-three"
								>
									24. TERMINATION
								</label>
								<div className="tab-content overflow-hidden border-l-2 bg-gray-100 border-[#03ce2f] leading-normal">
									<p className="px-5 py-3">
										In addition to any other legal or equitable remedies, we
										may, without prior notice to you, immediately terminate the
										Terms and Conditions or revoke any or all of your rights
										granted under the Terms and Conditions. Upon any termination
										of this Agreement, you shall immediately cease all access to
										and use of the Site and we shall, in addition to any other
										legal or equitable remedies, immediately revoke all
										password(s) and account identification issued to you and
										deny your access to and use of this Site in whole or in
										part. Any termination of this agreement shall not affect the
										respective rights and obligations (including without
										limitation, payment obligations) of the parties arising
										before the date of termination. You furthermore agree that
										the Site shall not be liable to you or to any other person
										as a result of any such suspension or termination. If you
										are dissatisfied with the Site or with any terms,
										conditions, rules, policies, guidelines, or practices of{' '}
										<strong>Cobai Global Resources Nigeria Limited</strong> in
										operating the Site, your sole and exclusive remedy is to
										discontinue using the Site.
									</p>
								</div>
							</div>
							<div className="tab w-full overflow-hidden border-t">
								<input
									className="absolute opacity-0"
									id="tab-multi-three"
									type="checkbox"
									name="tabs"
								/>
								<label
									className="block bg-white p-5 leading-normal cursor-pointer uppercase"
									htmlFor="tab-multi-three"
								>
									25. SEVERABILITY
								</label>
								<div className="tab-content overflow-hidden border-l-2 bg-gray-100 border-[#03ce2f] leading-normal">
									<p className="px-5 py-3">
										If any portion of these Terms and Conditions is held by any
										court or tribunal to be invalid or unenforceable, either in
										whole or in part, then that part shall be severed from these
										Terms and Conditions and shall not affect the validity or
										enforceability of any other section listed in this document.
									</p>
								</div>
							</div>
							<div className="tab w-full overflow-hidden border-t">
								<input
									className="absolute opacity-0"
									id="tab-multi-three"
									type="checkbox"
									name="tabs"
								/>
								<label
									className="block bg-white p-5 leading-normal cursor-pointer uppercase"
									htmlFor="tab-multi-three"
								>
									26. MISCELLANEOUS PROVISIONS
								</label>
								<div className="tab-content overflow-hidden border-l-2 bg-gray-100 border-[#03ce2f] leading-normal">
									<p className="px-5 py-3">
										You agree that all agreements, notices, disclosures and
										other communications that we provide to you electronically
										satisfy any legal requirement that such communications be in
										writing. Assigning or sub-contracting any of your rights or
										obligations under these Terms and Conditions to any third
										party is prohibited unless agreed upon in writing by the
										seller. We reserve the right to transfer, assign or
										sub-contract the benefit of the whole or part of any rights
										or obligations under these Terms and Conditions to any third
										party.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Terms;
