import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

function Vendor() {
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  return (
    <div>
      <div class="container px-6 py-2 mx-auto">
        <div class="items-center lg:flex">
          <div class="w-full lg:w-1/2">
            <div class="lg:max-w-lg">
              <h1 class="text-3xl font-semibold text-gray-800  lg:text-4xl">
                Best place to list your food
                <br /> and{" "}
                <span class="text-[#03ce2f] ">agro-allied Products</span>
              </h1>

              <p class="mt-3 text-gray-600 ">
                Giving Vendors an enabling system that helps them close
                legitimate deals and same time makes rock bottom sales both
                locally and internationally.
              </p>

              <div class="lg:max-w-lg">
                <div class="mt-8 space-y-5">
                  <p class="flex items-center -mx-2 text-gray-70">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-6 h-6 mx-2 text-[#03ce2f]"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>

                    <span class="mx-2">
                      Customer finds product and place the order on Cobai app
                    </span>
                  </p>

                  <p class="flex items-center -mx-2 text-gray-70">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-6 h-6 mx-2 text-[#03ce2f]"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>

                    <span class="mx-2">
                      You accept the order, and prepare the package
                    </span>
                  </p>

                  <p class="flex items-center -mx-2 text-gray-70">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-6 h-6 mx-2 text-[#03ce2f]"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>

                    <span class="mx-2">Delivery to the customer</span>
                  </p>
                </div>
              </div>

              <div className="mt-10">
                <Link
                  to={userInfo ? "/vendor" : "/signin?redirect=/vendor"}
                  className="w-full px-20 py-4  text-sm tracking-wider text-white uppercase transition-colors duration-300 transform bg-[#03ce2f] rounded-lg lg:w-auto hover:bg-[#03ce2f] focus:outline-none focus:bg-[#03ce2f]"
                >
                  Get Started
                </Link>
              </div>
            </div>
          </div>

          <div class="flex items-center justify-center w-full mt-6 lg:mt-0 lg:w-1/2">
            <img
              class="w-full h-full lg:max-w-lg"
              src="../img/send.png"
              alt="Catalogue-pana.svg"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Vendor;
