import React from "react";

const BannerBottom = () => {
  return (
    <section className="text-gray-600 body-font bg-gray-50 flex overflow-y-auto md:overflow-visible  whitespace-nowrap scrollbar-hide inset-x-0 items-center">
      <div className="md:container px-5 py-10 md:mx-auto">
        <div className="flex md:flex-wrap md:-m-4 text-center">
          <div className="p-1 md:p-4  hover:scale-105 duration-500 w-[12rem] lg:w-[19rem]">
            <div className=" flex items-center  justify-between p-4 gap-x-3 rounded-lg bg-white shadow-indigo-50 shadow-md">
              <div>
                <h2 className="text-gray-900 text-xs lg:text-lg font-semibold">
                  Fresh Fruits
                </h2>

                <p className="text-left text-[12px] lg:text-sm  text-gray-400">
                  9 Products
                </p>
              </div>
              <img
                src="https://thebravecoders-ecommerce-website-react-tailwind.vercel.app/category__1.webp"
                alt="Hotel"
                className="w-10 lg:w-20 lg:h-20  rounded-full border-white  border-dashed border-2  flex justify-center items-center "
              />
            </div>
          </div>
          <div className="p-1 md:p-4  hover:scale-105 duration-500 w-[12rem] lg:w-[19rem]">
            <div className=" flex items-center  justify-between p-4 gap-x-3 rounded-lg bg-white shadow-indigo-50 shadow-md">
              <div>
                <h2 className="text-gray-900 text-xs lg:text-lg font-semibold">
                  Fresh Vegs
                </h2>

                <p className="text-left text-[12px] lg:text-sm  text-gray-400">
                  9 Products
                </p>
              </div>
              <img
                src="https://thebravecoders-ecommerce-website-react-tailwind.vercel.app/category__2.webp"
                alt="Hotel"
                className="w-10 lg:w-20 lg:h-20  rounded-full border-white  border-dashed border-2  flex justify-center items-center "
              />
            </div>
          </div>
          <div className="p-1 md:p-4  hover:scale-105 duration-500 w-[12rem] lg:w-[19rem]">
            <div className=" flex items-center  justify-between p-4 gap-x-3 rounded-lg bg-white shadow-indigo-50 shadow-md">
              <div>
                <h2 className="text-gray-900 text-xs lg:text-lg font-semibold">
                  Canned Goods
                </h2>

                <p className="text-left text-[12px] lg:text-sm  text-gray-400">
                  9 Products
                </p>
              </div>
              <img
                src="https://thebravecoders-ecommerce-website-react-tailwind.vercel.app/category__3.webp"
                alt="Hotel"
                className="w-10 lg:w-20 lg:h-20  rounded-full border-white  border-dashed border-2  flex justify-center items-center "
              />
            </div>
          </div>
          <div className="p-1 md:p-4  hover:scale-105 duration-500 w-[12rem] lg:w-[19rem]">
            <div className=" flex items-center  justify-between p-4 gap-x-3 rounded-lg bg-white shadow-indigo-50 shadow-md">
              <div>
                <h2 className="text-gray-900 text-xs lg:text-lg font-semibold">
                  Bread & Bakery
                </h2>

                <p className="text-left text-[12px] lg:text-sm  text-gray-400">
                  9 Products
                </p>
              </div>
              <img
                src="https://thebravecoders-ecommerce-website-react-tailwind.vercel.app/category__4.webp"
                alt="Hotel"
                className="w-10 lg:w-20 lg:h-20  rounded-full border-white  border-dashed border-2  flex justify-center items-center "
              />
            </div>
          </div>
          <div className="p-1 md:p-4  hover:scale-105 duration-500 w-[12rem] lg:w-[19rem]">
            <div className=" flex items-center  justify-between p-4 gap-x-3 rounded-lg bg-white shadow-indigo-50 shadow-md">
              <div>
                <h2 className="text-left text-gray-900 text-xs lg:text-lg font-semibold">
                  Frozen
                </h2>

                <p className="text-left text-[12px] lg:text-sm  text-gray-400">
                  9 Products
                </p>
              </div>
              <img
                src="https://thebravecoders-ecommerce-website-react-tailwind.vercel.app/category__5.webp"
                alt="Hotel"
                className="w-10 lg:w-20 lg:h-20  rounded-full border-white  border-dashed border-2  flex justify-center items-center "
              />
            </div>
          </div>
          <div className="p-1 md:p-4  hover:scale-105 duration-500 w-[12rem] lg:w-[19rem]">
            <div className=" flex items-center  justify-between p-4 gap-x-3 rounded-lg bg-white shadow-indigo-50 shadow-md">
              <div>
                <h2 className="text-gray-900 text-xs lg:text-lg font-semibold">
                  Eggs & Dairy
                </h2>

                <p className="text-left text-[12px] lg:text-sm  text-gray-400">
                  9 Products
                </p>
              </div>
              <img
                src="https://thebravecoders-ecommerce-website-react-tailwind.vercel.app/category__6.webp"
                alt="Hotel"
                className="w-10 lg:w-20 lg:h-20  rounded-full border-white  border-dashed border-2  flex justify-center items-center "
              />
            </div>
          </div>
          <div className="p-1 md:p-4  hover:scale-105 duration-500 w-[12rem] lg:w-[19rem]">
            <div className=" flex items-center  justify-between p-4 gap-x-3 rounded-lg bg-white shadow-indigo-50 shadow-md">
              <div>
                <h2 className="text-gray-900 text-xs lg:text-lg font-semibold">
                  Soft Drinks
                </h2>

                <p className="text-left text-[12px] lg:text-sm  text-gray-400">
                  9 Products
                </p>
              </div>
              <img
                src="https://thebravecoders-ecommerce-website-react-tailwind.vercel.app/category__7.webp"
                alt="Hotel"
                className="w-10 lg:w-20 lg:h-20  rounded-full border-white  border-dashed border-2  flex justify-center items-center "
              />
            </div>
          </div>
          <div className="p-1 md:p-4  hover:scale-105 duration-500 w-[12rem] lg:w-[19rem]">
            <div className=" flex items-center  justify-between p-4 gap-x-3 rounded-lg bg-white shadow-indigo-50 shadow-md">
              <div>
                <h2 className="text-gray-900 text-xs lg:text-lg font-semibold">
                  AgroAllied
                </h2>

                <p className="text-left text-[12px] lg:text-sm  text-gray-400">
                  9 Products
                </p>
              </div>
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQFxCJ1-ZaBEc5IMhACAkSmdiAgLG5U7IPPYw&usqp=CAU"
                alt="Hotel"
                className="w-10 h-10 lg:w-20 lg:h-20  rounded-full border-white  border-dashed border-2  flex justify-center items-center "
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BannerBottom;
