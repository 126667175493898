import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { detailsUser, updateUserProfile } from '../actions/userActions';
// import LoadingBox from '../components/LoadingBox';
// import MessageBox from '../components/MessageBox';
// import Swal from 'sweetalert2';
// import { USER_UPDATE_PROFILE_RESET } from '../constants/userConstants';
import Axios from "axios";

export default function Profile() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [sellerCity, setSellerCity] = useState("");
  const [sellerProvince, setSellerProvince] = useState("");
  const [sellerCountry, setSellerCountry] = useState("");
  const [sellerStoreName, setSellerStoreName] = useState("");
  const [sellerStoreAddress, setSellerStoreAddress] = useState("");
  const [sellerPhoneNumber, setSellerPhoneNumber] = useState("");
  const [sellerBusinessType, setSellerBusinessType] = useState("");
  const [sellerCAC, setSellerCAC] = useState("");
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [errorUpload, setErrorUpload] = useState("");
  // const uploadFileHandler = async (e) => {
  // 	const file = e.target.files[0];
  // 	const bodyFormData = new FormData();
  // 	bodyFormData.append('image', file);
  // 	setLoadingUpload(true);
  // 	try {
  // 		const { data } = await Axios.post('/api/uploads', bodyFormData, {
  // 			headers: {
  // 				'Content-Type': 'multipart/form-data',
  // 				Authorization: `Bearer ${userInfo.token}`,
  // 			},
  // 		});
  // 		setSellerCAC(data);
  // 		setLoadingUpload(false);
  // 	} catch (error) {
  // 		setErrorUpload(error.message);
  // 		setLoadingUpload(false);
  // 	}
  // };

  // const userSignin = useSelector((state) => state.userSignin);
  // const { userInfo } = userSignin;
  // const userDetails = useSelector((state) => state.userDetails);
  // const { loading, error, user } = userDetails;
  // const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
  // const {
  // 	success: successUpdate,
  // 	error: errorUpdate,
  // 	loading: loadingUpdate,
  // } = userUpdateProfile;
  // const dispatch = useDispatch();
  // useEffect(() => {
  // 	if (!user) {
  // 		dispatch({ type: USER_UPDATE_PROFILE_RESET });
  // 		dispatch(detailsUser(userInfo._id));
  // 	} else {
  // 		setName(user.name);
  // 		setEmail(user.email);
  // 		if (user.seller) {
  // 			setSellerCity(user.seller.city);
  // 			setSellerProvince(user.seller.province);
  // 			setSellerCountry(user.seller.country);
  // 			setSellerStoreName(user.seller.storeName);
  // 			setSellerStoreAddress(user.seller.storeAddress);
  // 			setSellerPhoneNumber(user.seller.phoneNumber);
  // 			setSellerBusinessType(user.seller.businessType);
  // 			setSellerCAC(user.seller.cac);
  // 		}
  // 	}
  // }, [dispatch, userInfo._id, user]);
  // const submitHandler = (e) => {
  // 	e.preventDefault();
  // 	// dispatch update profile
  // 	if (password !== confirmPassword) {
  // 		Swal.fire({
  // 			title: 'Oops...',
  // 			text: 'Password and Confirm Password Are Not Matched',
  // 			icon: 'error',
  // 		});
  // 	} else {
  // 		dispatch(
  // 			updateUserProfile({
  // 				userId: user._id,
  // 				name,
  // 				email,
  // 				password,
  // 				sellerCity,
  // 				sellerProvince,
  // 				sellerCountry,
  // 				sellerStoreName,
  // 				sellerStoreAddress,
  // 				sellerPhoneNumber,
  // 				sellerBusinessType,
  // 				sellerCAC,
  // 			})
  // 		);
  // 	}
  // };
  return (
    <div className="main-wrapper pt-5 pb-5">
      {/* {loading ? (
				<LoadingBox></LoadingBox>
			) : error ? (
				<MessageBox variant="danger">{error}</MessageBox>
			) : ( */}
      <main id="skip-target">
        <form
          className="form"
          // onSubmit={submitHandler}
        >
          {/* {loadingUpdate && <LoadingBox></LoadingBox>}
						
						{errorUpdate && <MessageBox variant="text-red-500 bg-red-100 my-1 ">{errorUpdate} </MessageBox>}
						{successUpdate && (
							<MessageBox variant="success">
								Profile Updated Successfully
							</MessageBox>
						)} */}
          <div className="row">
            <div className="col-lg-12">
              <h5 className="mb-2">Account Details</h5>
              <div className="row">
                <div className="col-sm-6">
                  <label htmlFor="name">Name</label>
                  <input
                    id="name"
                    type="text"
                    placeholder="Enter name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="form-control"
                    required
                  />
                </div>

                <div className="col-sm-6">
                  <label htmlFor="email">Email</label>
                  <input
                    id="email"
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="form-control"
                    required
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6">
                  <label htmlFor="password">Password</label>
                  <input
                    id="password"
                    type="password"
                    placeholder="Enter password"
                    onChange={(e) => setPassword(e.target.value)}
                    className="form-control"
                  />
                </div>
                <div className="col-sm-6">
                  <label htmlFor="confirmPassword">Confirm Password</label>
                  <input
                    id="confirmPassword"
                    type="password"
                    placeholder="Enter confirm password"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="form-control"
                  />
                </div>
              </div>

              {/* {user.isSeller && ( */}
              <div className="row">
                <div className="col-sm-6">
                  <label htmlFor="sellerStoreName">Store Name</label>
                  <input
                    id="sellerStoreName"
                    type="text"
                    placeholder="Enter Store Name"
                    value={sellerStoreName}
                    onChange={(e) => setSellerStoreName(e.target.value)}
                    className="form-control"
                  />
                </div>
                <div className="col-sm-6">
                  <label htmlFor="sellerStoreAddress">Store Address</label>
                  <input
                    id="sellerStoreAddress"
                    type="text"
                    placeholder="Enter Address"
                    value={sellerStoreAddress}
                    onChange={(e) => setSellerStoreAddress(e.target.value)}
                    className="form-control"
                  />
                </div>
                <div className="col-sm-6">
                  <label htmlFor="sellerCity">City</label>
                  <input
                    id="sellerCity"
                    type="text"
                    placeholder="Enter City"
                    value={sellerCity}
                    onChange={(e) => setSellerCity(e.target.value)}
                    className="form-control"
                  />
                </div>
                <div className="col-sm-6">
                  <label htmlFor="sellerCity">State</label>
                  <input
                    id="sellerProvince"
                    type="text"
                    placeholder="Enter State"
                    value={sellerProvince}
                    onChange={(e) => setSellerProvince(e.target.value)}
                    className="form-control"
                  />
                </div>
                <div className="col-sm-6">
                  <label htmlFor="sellerCountry">Country</label>
                  <input
                    id="sellerCountry"
                    type="text"
                    placeholder="Enter Country"
                    value={sellerCountry}
                    onChange={(e) => setSellerCountry(e.target.value)}
                    className="form-control"
                  />
                </div>
                <div className="col-sm-6">
                  <label htmlFor="sellerPhoneNumber">Phone Number</label>
                  <input
                    id="sellerPhoneNumber"
                    type="text"
                    placeholder="Enter Phone Number"
                    value={sellerPhoneNumber}
                    onChange={(e) => setSellerPhoneNumber(e.target.value)}
                    className="form-control"
                  />
                </div>

                <div className="col-sm-6">
                  <label htmlFor="sellerPhoneNumber">Business Type</label>
                  <select
                    value={sellerBusinessType}
                    onChange={(e) => setSellerBusinessType(e.target.value)}
                    className="form-control"
                  >
                    <option>{sellerBusinessType}</option>
                    <option value="restaurant">RESTAURANT</option>
                    <option value="frozen">FROZEN FOODS</option>
                    <option value="florist">FLOURIST</option>
                    <option value="friuts">FRUITS & VEGETABLES</option>
                    <option value="agro">FARM PRODUCE</option>
                    <option value="livestock">LIVESTOCK</option>
                    <option value="agro-allied">AGRO-ALLIED</option>
                    <option value="kitchen">KITCHEN ACCESSORIES</option>
                    <option value="grocery">GROCERY</option>
                    <option value="pastry">PASTRY</option>
                  </select>
                </div>

                <div className="col-sm-6">
                  <label htmlFor="imageFile">
                    CAC/Other Authorized Document
                  </label>

                  <input
                    type="file"
                    id="imageFile"
                    label="Choose Image"
                    // onChange={uploadFileHandler}
                    className="form-control"
                  />

                  {/* {loadingUpload && <LoadingBox></LoadingBox>}
											{errorUpload && (
												<MessageBox variant="text-red-500 bg-red-100 my-1 ">{errorUpload}</MessageBox>
											)} */}
                </div>
              </div>
              {/* )} */}

              <div className="col-sm-6">
                <button
                  type="submit"
                  className="btn btn-outline-primary-2 btn-order btn-block"
                >
                  <span className="btn-text">Update</span>
                  {/* <span className="btn-hover-text">Proceed </span> */}
                </button>
              </div>
            </div>
          </div>
        </form>
      </main>
      {/* )} */}
    </div>
  );
}
