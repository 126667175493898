import React from 'react';

function Settings() {
	return (
		<>
			<div class="flex items-center gap-x-10 p-4 ">
				<div class="flex items-baseline py-6">
					<div class=" flex-none rounded-full border-2 overflow-hidden">
						<img
							class="w-[9rem] h-auto  object-cover"
							src="../ava.png"
							alt="Avatar Upload"
						/>
					</div>
					<label class="cursor-pointer -ml-10">
						<svg
							className="w-7 h-auto"
							width="40"
							height="40"
							viewBox="0 0 40 40"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<circle cx="20" cy="20" r="20" fill="#26B893" />
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M25.44 14.2364C25.48 14.3063 25.55 14.3563 25.64 14.3563C28.04 14.3563 30 16.3141 30 18.7114V24.6448C30 27.0422 28.04 29 25.64 29H14.36C11.95 29 10 27.0422 10 24.6448V18.7114C10 16.3141 11.95 14.3563 14.36 14.3563C14.44 14.3563 14.52 14.3163 14.55 14.2364L14.61 14.1165C14.6445 14.044 14.6799 13.9694 14.7158 13.8938C14.9716 13.3549 15.2546 12.7588 15.43 12.4084C15.89 11.5094 16.67 11.01 17.64 11H22.35C23.32 11.01 24.11 11.5094 24.57 12.4084C24.7275 12.7231 24.9674 13.2299 25.1987 13.7184C25.2464 13.8192 25.2938 13.9192 25.34 14.0166L25.44 14.2364ZM24.71 18.0721C24.71 18.5716 25.11 18.9711 25.61 18.9711C26.11 18.9711 26.52 18.5716 26.52 18.0721C26.52 17.5727 26.11 17.1632 25.61 17.1632C25.11 17.1632 24.71 17.5727 24.71 18.0721ZM18.27 19.6204C18.74 19.1509 19.35 18.9012 20 18.9012C20.65 18.9012 21.26 19.1509 21.72 19.6104C22.18 20.0699 22.43 20.6792 22.43 21.3285C22.42 22.667 21.34 23.7558 20 23.7558C19.35 23.7558 18.74 23.5061 18.28 23.0466C17.82 22.5871 17.57 21.9778 17.57 21.3285V21.3185C17.56 20.6892 17.81 20.0799 18.27 19.6204ZM22.77 24.1054C22.06 24.8147 21.08 25.2542 20 25.2542C18.95 25.2542 17.97 24.8446 17.22 24.1054C16.48 23.3563 16.07 22.3774 16.07 21.3285C16.06 20.2897 16.47 19.3108 17.21 18.5616C17.96 17.8124 18.95 17.4029 20 17.4029C21.05 17.4029 22.04 17.8124 22.78 18.5516C23.52 19.3008 23.93 20.2897 23.93 21.3285C23.92 22.4173 23.48 23.3962 22.77 24.1054Z"
								fill="white"
							/>
						</svg>
						<input type="file" class="hidden" />
					</label>
				</div>
				<div className="">
					<h2 className="text-[#083A50] font-extrabold text-xl py-1 text-left  block ">
						Isaac Onah
					</h2>

					<h2 className="text-[#083A50]   text-sm font-light text-left   ">
						Your account is ready, you can now apply for advice.
					</h2>
				</div>
			</div>

			<div class=" p-4">
				<h2 className="text-[#CACED8] font-extrabold text-xl py-1 text-left  block ">
					Edit Profile
				</h2>

				<div class="grid grid-cols-6 lg:grid-cols-12 gap-2 lg:gap-x-3 w-full items-center justify-center my-6 ">
					<div class="col-span-6  w-full  bg-trasparent rounded-xl ">
						<h2 className="text-[#CACED8] font-normal text-xl py-2 text-left  block ">
							Personal
						</h2>

						<div class="flex flex-col md:flex-row">
							<div class="w-full mx-2 flex-1 svelte-1l8159u">
								<div class="font-bold h-6 mt-3 text-gray-600 text-xs leading-8 uppercase">
									First Name
								</div>
								<div class="bg-white my-2 p-1 flex border border-gray-200 rounded svelte-1l8159u">
									<input
										placeholder="mobina"
										class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
									/>
								</div>
							</div>
							<div class="w-full mx-2 flex-1 svelte-1l8159u">
								<div class="font-bold h-6 mt-3 text-gray-600 text-xs leading-8 uppercase">
									Surname
								</div>
								<div class="bg-white my-2 p-1 flex border border-gray-200 rounded svelte-1l8159u">
									<input
										placeholder="jhon@doe.com"
										class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
									/>
								</div>
							</div>
						</div>
						<div class="w-full mx-2 flex-1 svelte-1l8159u">
							<div class="font-bold h-6 mt-2 text-gray-600 text-xs leading-8 uppercase">
								National Code
							</div>
							<div class="bg-white my-2 p-1 flex border border-gray-200 rounded svelte-1l8159u">
								<input
									placeholder="Enter Value"
									class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
								/>
							</div>
						</div>
						<div class="w-full mx-2 flex-1 svelte-1l8159u">
							<div class="font-bold h-6 mt-3 text-gray-600 text-xs leading-8 uppercase">
								Date of birth
							</div>
							<div class="bg-white my-2 p-1 flex border border-gray-200 rounded svelte-1l8159u">
								<input
									placeholder="Enter Value"
									class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
								/>
							</div>
						</div>
						<div class="w-full mx-2 flex-1 svelte-1l8159u">
							<div class="font-bold h-6 mt-3 text-gray-600 text-xs leading-8 uppercase">
								Education level
							</div>
							<div class="relative">
								<select class="py-2 my-1 block appearance-none border text-[#CACED8] bg-[#FFF] rounded-sm w-full focus:outline-none px-5">
									<option selected>Select</option>
									<option value="male">Nigeria</option>
									<option value="female">Ghana</option>
								</select>
								<div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4 text-gray-700">
									<svg
										class="fill-current h-4 w-4"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 20 20"
									>
										<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
									</svg>
								</div>
							</div>
						</div>
					</div>

					<div class="col-span-6  w-full  bg-trasparent rounded-xl ">
						<h2 className="text-[#CACED8] font-normal text-xl py-2 text-left  block ">
							Contact
						</h2>

						<div class="w-full mx-2 flex-1 svelte-1l8159u">
							<div class="font-bold h-6 mt-3 text-gray-600 text-xs leading-8 uppercase">
								Email
							</div>
							<div class="bg-white my-2 p-1 flex border border-gray-200 rounded svelte-1l8159u">
								<input
									placeholder="mobina"
									class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
								/>
							</div>
						</div>
						<div class="w-full mx-2 flex-1 svelte-1l8159u">
							<div class="font-bold h-6 mt-3 text-gray-600 text-xs leading-8 uppercase">
								Phone Number
							</div>
							<div className="flex items-center gap-x-3">
								<div class="relative">
									<select class="py-2 my-1 block appearance-none border text-[#CACED8] bg-[#FFF] rounded-sm w-full focus:outline-none pl-3 mr-9">
										<option selected>+98</option>
										<option value="male">+98</option>
										<option value="female">+98</option>
									</select>
									<div class="pointer-events-none absolute inset-y-0 right-0 flex items-center  text-gray-700 px-2">
										<svg
											class="fill-current h-4 w-4"
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 20 20"
										>
											<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
										</svg>
									</div>
								</div>
								<div class="bg-white my-2 p-1 flex border border-gray-200 rounded svelte-1l8159u w-full">
									<input
										placeholder="9120000000"
										class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
									/>
								</div>
							</div>
						</div>

						<div class="w-full mx-2 flex-1 svelte-1l8159u">
							<div class="font-bold h-6 mt-3 text-gray-600 text-xs leading-8 uppercase">
								Country
							</div>
							<div class="relative">
								<select class="py-2 my-1 block appearance-none border text-[#CACED8] bg-[#FFF] rounded-sm w-full focus:outline-none px-5">
									<option selected>Select</option>
									<option value="male">Nigeria</option>
									<option value="female">Ghana</option>
								</select>
								<div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4 text-gray-700">
									<svg
										class="fill-current h-4 w-4"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 20 20"
									>
										<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
									</svg>
								</div>
							</div>
						</div>
						<div class="w-full mx-2 flex-1 svelte-1l8159u">
							<div class="font-bold h-6 mt-3 text-gray-600 text-xs leading-8 uppercase">
								City
							</div>
							<div class="relative">
								<select class="py-2 my-1 block appearance-none border text-[#CACED8] bg-[#FFF] rounded-sm w-full focus:outline-none px-5">
									<option selected>Select</option>
									<option value="male">Nigeria</option>
									<option value="female">Ghana</option>
								</select>
								<div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4 text-gray-700">
									<svg
										class="fill-current h-4 w-4"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 20 20"
									>
										<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
									</svg>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Settings;
