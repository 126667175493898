import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BsCheckCircleFill } from "react-icons/bs";

import { logoLight } from "../../assets/images";
import { register } from "../../actions/userActions";
import MessageBox from "../../components/MessageBox";
import LoadingBox from "../../components/LoadingBox";

const SignUp = () => {
  const [checked, setChecked] = useState(false);
  const [passwordType, setPasswordType] = useState("password");

  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const { search } = useLocation();
  const redirectInUrl = new URLSearchParams(search).get("redirect");
  const redirect = redirectInUrl ? redirectInUrl : "/";

  const userRegister = useSelector((state) => state.userRegister);
  const { userInfo, loading, error } = userRegister;

  const dispatch = useDispatch();
  const submitHandler = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert("Password are not match");
    } else {
      dispatch(register(name, email, password));
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    if (userInfo) {
      navigate(redirect);
    }
  }, [navigate, redirect, userInfo]);
  return (
    <div className="w-full h-screen flex items-center justify-start">
      <div className="hidden bg-slate-600 lg:inline-flex h-full text-white">
        <div className="w-[450px] h-full bg-primeColor px-10 flex flex-col gap-6 justify-center">
          <Link to="/">
            <img src={logoLight} alt="logoImg" className="w-28" />
          </Link>
          <div className="flex flex-col gap-1 -mt-1">
            <h1 className="font-titleFont text-xl font-medium">
              Get started for free
            </h1>

            <p className="text-base">Create your account to access more</p>
          </div>
          <div className="w-[300px] flex items-start gap-3">
            <span className="text-green-500 mt-1">
              <BsCheckCircleFill />
            </span>
            <p className="text-base text-gray-300">
              <span className="text-white font-semibold font-titleFont">
                Get started fast with COBAI
              </span>
              <br />
              By creating an account with our store, you will be able to move
              through the checkout process faster
            </p>
          </div>
          <div className="w-[300px] flex items-start gap-3">
            <span className="text-green-500 mt-1">
              <BsCheckCircleFill />
            </span>
            <p className="text-base text-gray-300">
              <span className="text-white font-semibold font-titleFont">
                Access all COBAI services
              </span>
              <br />
              Just with a click of the button you can access loads of COBAI's
              offerings seamlessly.
            </p>
          </div>
          <div className="w-[300px] flex items-start gap-3">
            <span className="text-green-500 mt-1">
              <BsCheckCircleFill />
            </span>
            <p className="text-base text-gray-300">
              <span className="text-white font-semibold font-titleFont">
                Trusted by online Shoppers
              </span>
              <br />
              Join the Bandwagon of Millions that take COBAI as their everyday
              shopping Plug and enjoy our juicy offers without fear of being
              Scammed.
            </p>
          </div>
          <div className="flex items-center justify-between mt-10">
            <Link to="/">
              <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
                © COBAI
              </p>
            </Link>
            <Link to="/terms">
              <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
                Terms
              </p>
            </Link>
            <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
              Privacy
            </p>
            <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
              Security
            </p>
          </div>
        </div>
      </div>
      <div className="w-full  h-full">
        <form
          onSubmit={submitHandler}
          className="mx-auto md:w-[650px] h-screen flex items-center justify-center"
        >
          <div className="px-6 py-4 w-full flex flex-col justify-center ">
            <div class="text-center my-4">
              <div class="flex justify-center mx-auto ">
                <svg
                  className="w-[7rem] h-[6rem]"
                  width="134"
                  height="134"
                  viewBox="0 0 134 134"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M67 11.1667C36.18 11.1667 11.1667 36.18 11.1667 67C11.1667 97.82 36.18 122.833 67 122.833C97.82 122.833 122.833 97.82 122.833 67C122.833 36.18 97.82 11.1667 67 11.1667ZM67 33.5C77.7758 33.5 86.5417 42.2658 86.5417 53.0417C86.5417 63.8175 77.7758 72.5833 67 72.5833C56.2242 72.5833 47.4583 63.8175 47.4583 53.0417C47.4583 42.2658 56.2242 33.5 67 33.5ZM67 111.667C55.6658 111.667 42.2658 107.088 32.7183 95.5867C42.4981 87.9136 54.5694 83.7433 67 83.7433C79.4306 83.7433 91.5019 87.9136 101.282 95.5867C91.7342 107.088 78.3342 111.667 67 111.667Z"
                    fill="#E7E7E7"
                  />
                </svg>
              </div>

              <h1 className=" text-3xl font-bold text-center text-[#000] ">
                Welcome to Cobai!
              </h1>

              <p className=" px-12 text-sm text-center text-gray-500 ">
                By creating an account with our store, you will be able to move
                through the checkout process faster.
              </p>
            </div>

            {loading && <LoadingBox />}
            {error && (
              <MessageBox variant="text-red-500 bg-red-100 my-1 ">
                {error}{" "}
              </MessageBox>
            )}
            <div className="flex flex-col gap-7">
              {/* Name */}
              <div className="flex flex-col gap-.5">
                <input
                  required
                  onChange={(e) => setName(e.target.value)}
                  className="w-full  placeholder:text-sm placeholder:tracking-wide p-4 text-base font-medium placeholder:font-normal rounded-3xl border-[1px] border-gray-400 outline-none"
                  type="type"
                  placeholder="Enter Full Name"
                />
              </div>
              {/* Email */}
              <div className="flex flex-col gap-.5">
                <input
                  required
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full  placeholder:text-sm placeholder:tracking-wide p-4 text-base font-medium placeholder:font-normal rounded-3xl border-[1px] border-gray-400 outline-none"
                  type="email"
                  placeholder="john@workemail.com"
                />
              </div>

              {/* Password */}
              <div className="flex flex-col gap-.5">
                {/* <p className="font-titleFont text-base font-semibold ml-5 py-1 text-gray-600">
                    Password
                  </p> */}
                <div class="flex ">
                  <input
                    required
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full  placeholder:text-sm placeholder:tracking-wide p-4 text-base font-medium placeholder:font-normal rounded-3xl border-[1px] border-gray-400 outline-none"
                    type={passwordType}
                    placeholder="Enter password"
                  />
                  <span className="relative -ml-12 sm:-ml-16 flex items-center px-3 leading-normal text-gray-600 whitespace-no-wrap border-0 rounded rounded-l-none">
                    <button
                      type="button"
                      onClick={() => {
                        if (passwordType === "password") {
                          setPasswordType("text");
                          return;
                        }
                        setPasswordType("password");
                      }}
                      className="flex justify-center w-4 sm:w-full py-1 text-base text-gray-500 transition duration-200 ease-in-out rounded cursor-pointer hover:scale-110 focus:outline-none"
                    >
                      {passwordType === "password" ? (
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_5982_274025)">
                            <path
                              d="M17.94 17.94C16.2306 19.243 14.1491 19.9649 12 20C5 20 1 12 1 12C2.24389 9.68192 3.96914 7.65663 6.06 6.06003M9.9 4.24002C10.5883 4.0789 11.2931 3.99836 12 4.00003C19 4.00003 23 12 23 12C22.393 13.1356 21.6691 14.2048 20.84 15.19M14.12 14.12C13.8454 14.4148 13.5141 14.6512 13.1462 14.8151C12.7782 14.9791 12.3809 15.0673 11.9781 15.0744C11.5753 15.0815 11.1752 15.0074 10.8016 14.8565C10.4281 14.7056 10.0887 14.4811 9.80385 14.1962C9.51897 13.9113 9.29439 13.572 9.14351 13.1984C8.99262 12.8249 8.91853 12.4247 8.92563 12.0219C8.93274 11.6191 9.02091 11.2219 9.18488 10.8539C9.34884 10.4859 9.58525 10.1547 9.88 9.88003"
                              stroke="#757E7E"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M1 1L23 23"
                              stroke="#757E7E"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_5982_274025">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      ) : (
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 6.5C13.8387 6.49389 15.6419 7.00678 17.2021 7.97973C18.7624 8.95267 20.0164 10.3462 20.82 12C19.17 15.37 15.8 17.5 12 17.5C8.2 17.5 4.83 15.37 3.18 12C3.98362 10.3462 5.23763 8.95267 6.79788 7.97973C8.35813 7.00678 10.1613 6.49389 12 6.5ZM12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 9.5C12.663 9.5 13.2989 9.76339 13.7678 10.2322C14.2366 10.7011 14.5 11.337 14.5 12C14.5 12.663 14.2366 13.2989 13.7678 13.7678C13.2989 14.2366 12.663 14.5 12 14.5C11.337 14.5 10.7011 14.2366 10.2322 13.7678C9.76339 13.2989 9.5 12.663 9.5 12C9.5 11.337 9.76339 10.7011 10.2322 10.2322C10.7011 9.76339 11.337 9.5 12 9.5ZM12 7.5C9.52 7.5 7.5 9.52 7.5 12C7.5 14.48 9.52 16.5 12 16.5C14.48 16.5 16.5 14.48 16.5 12C16.5 9.52 14.48 7.5 12 7.5Z"
                            fill="#757E7E"
                          />
                        </svg>
                      )}
                    </button>
                  </span>
                </div>
              </div>
              {/* Password */}
              <div className="flex flex-col gap-.5">
                {/* <p className="font-titleFont text-base font-semibold ml-5 py-1 text-gray-600">
                    Confirm Password
                  </p> */}
                <div class="flex ">
                  <input
                    required
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="w-full  placeholder:text-sm placeholder:tracking-wide p-4 text-base font-medium placeholder:font-normal rounded-3xl border-[1px] border-gray-400 outline-none"
                    type={passwordType}
                    placeholder="Confirm password"
                  />
                  <span className="relative -ml-12 sm:-ml-16 flex items-center px-3 leading-normal text-gray-600 whitespace-no-wrap border-0 rounded rounded-l-none">
                    <button
                      type="button"
                      onClick={() => {
                        if (passwordType === "password") {
                          setPasswordType("text");
                          return;
                        }
                        setPasswordType("password");
                      }}
                      className="flex justify-center w-4 sm:w-full py-1 text-base text-gray-500 transition duration-200 ease-in-out rounded cursor-pointer hover:scale-110 focus:outline-none"
                    >
                      {passwordType === "password" ? (
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_5982_274025)">
                            <path
                              d="M17.94 17.94C16.2306 19.243 14.1491 19.9649 12 20C5 20 1 12 1 12C2.24389 9.68192 3.96914 7.65663 6.06 6.06003M9.9 4.24002C10.5883 4.0789 11.2931 3.99836 12 4.00003C19 4.00003 23 12 23 12C22.393 13.1356 21.6691 14.2048 20.84 15.19M14.12 14.12C13.8454 14.4148 13.5141 14.6512 13.1462 14.8151C12.7782 14.9791 12.3809 15.0673 11.9781 15.0744C11.5753 15.0815 11.1752 15.0074 10.8016 14.8565C10.4281 14.7056 10.0887 14.4811 9.80385 14.1962C9.51897 13.9113 9.29439 13.572 9.14351 13.1984C8.99262 12.8249 8.91853 12.4247 8.92563 12.0219C8.93274 11.6191 9.02091 11.2219 9.18488 10.8539C9.34884 10.4859 9.58525 10.1547 9.88 9.88003"
                              stroke="#757E7E"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M1 1L23 23"
                              stroke="#757E7E"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_5982_274025">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      ) : (
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 6.5C13.8387 6.49389 15.6419 7.00678 17.2021 7.97973C18.7624 8.95267 20.0164 10.3462 20.82 12C19.17 15.37 15.8 17.5 12 17.5C8.2 17.5 4.83 15.37 3.18 12C3.98362 10.3462 5.23763 8.95267 6.79788 7.97973C8.35813 7.00678 10.1613 6.49389 12 6.5ZM12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 9.5C12.663 9.5 13.2989 9.76339 13.7678 10.2322C14.2366 10.7011 14.5 11.337 14.5 12C14.5 12.663 14.2366 13.2989 13.7678 13.7678C13.2989 14.2366 12.663 14.5 12 14.5C11.337 14.5 10.7011 14.2366 10.2322 13.7678C9.76339 13.2989 9.5 12.663 9.5 12C9.5 11.337 9.76339 10.7011 10.2322 10.2322C10.7011 9.76339 11.337 9.5 12 9.5ZM12 7.5C9.52 7.5 7.5 9.52 7.5 12C7.5 14.48 9.52 16.5 12 16.5C14.48 16.5 16.5 14.48 16.5 12C16.5 9.52 14.48 7.5 12 7.5Z"
                            fill="#757E7E"
                          />
                        </svg>
                      )}
                    </button>
                  </span>
                </div>
              </div>

              {/* Checkbox */}
              <div className="flex items-start mdl:items-center gap-2">
                <input
                  onChange={() => setChecked(!checked)}
                  className="w-4 h-4 mt-1 mdl:mt-0 cursor-pointer"
                  type="checkbox"
                />
                <p className="text-sm text-primeColor">
                  I agree to the COBAI{" "}
                  <span className="text-green-600">Terms of Service </span>and{" "}
                  <span className="text-green-600">Privacy Policy</span>.
                </p>
              </div>

              <button
                type="submit"
                className={`${
                  checked
                    ? "w-full bg-green-500   text-white font-semibold transition duration-200 hover:bg-[#757E7E] cursor-pointer"
                    : "bg-gray-500 hover:bg-gray-500 hover:text-gray-200 cursor-none rounded-3xl p-3"
                } w-full text-gray-200 text-base font-medium rounded-3xl p-3  hover:text-white duration-300`}
              >
                Create Account
              </button>

              <p className="text-sm text-center font-titleFont font-semibold">
                I have an Account?{" "}
                <Link to={`/signin`}>
                  <span className="hover:text-green-600 text-red-500 duration-300">
                    Register
                  </span>
                </Link>
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignUp;
