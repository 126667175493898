import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { HiOutlineMenuAlt4 } from "react-icons/hi";
import { FaSearch, FaUser, FaCaretDown, FaShoppingCart } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { paginationItems } from "../../../constants";
import { listProductCategories } from "../../../actions/productActions";
import LoadingBox from "../../LoadingBox";
import MessageBox from "../../MessageBox";

const HeaderBottom = () => {
  // const products = useSelector((state) => state.orebiReducer.products);
  // const { cartItems } = useSelector((state) => state.cart);
  // const { userInfo } = useSelector((state) => state.auth);

  // const dispatch = useDispatch();

  // const [logoutApiCall] = useLogoutMutation();

  // const logoutHandler = async () => {
  // 	try {
  // 		await logoutApiCall().unwrap();
  // 		dispatch(logout());
  // 		// NOTE: here we need to reset cart state for when a user logs out so the next
  // 		// user doesn't inherit the previous users cart and shipping
  // 		dispatch(resetCart());
  // 		navigate('/login');
  // 	} catch (err) {
  // 		console.error(err);
  // 	}
  // };

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const [show, setShow] = useState(false);
  const [showUser, setShowUser] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const productCategoryList = useSelector((state) => state.productCategoryList);
  const {
    loading: loadingCategories,
    error: errorCategories,
    categories,
  } = productCategoryList;
  useEffect(() => {
    dispatch(listProductCategories());
  }, [dispatch]);
  // const ref = useRef();
  // useEffect(() => {
  //   document.body.addEventListener("click", (e) => {
  //     if (ref.current.contains(e.target)) {
  //       setShow(true);
  //     } else {
  //       setShow(false);
  //     }
  //   });
  // }, [show, ref]);

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [showSearchBar, setShowSearchBar] = useState(false);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    const filtered = paginationItems.filter((item) =>
      item.productName.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredProducts(filtered);
  }, [searchQuery]);

  return (
    <div className="w-full bg-[#F5F5F3] relative">
      <div className="max-w-container mx-auto">
        <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between w-full px-4 pb-4 lg:pb-0 h-full lg:h-24">
          <div className="flex items-center justify-between w-full lg:w-auto">
            <div
              onClick={() => setShow(!show)}
              // ref={ref}
              className="flex h-14 cursor-pointer items-center gap-2 text-primeColor"
            >
              <HiOutlineMenuAlt4 className="w-5 h-5" />
              <p className="text-[14px] font-normal">Shop by Category</p>

              {show && (
                <motion.ul
                  initial={{ y: 30, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.5 }}
                  className="absolute top-36 z-50 bg-primeColor w-auto text-[#767676] h-auto p-4 pb-6"
                >
                  {loadingCategories ? (
                    <LoadingBox />
                  ) : errorCategories ? (
                    <MessageBox variant="text-red-500 bg-red-100 my-1 ">
                      {errorCategories}
                    </MessageBox>
                  ) : (
                    categories.map((c) => (
                      <li
                        className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer"
                        key={c}
                      >
                        <Link to={`search/category/${c}`}>{c}</Link>
                      </li>
                    ))
                  )}
                </motion.ul>
              )}
            </div>
            <div className="lg:hidden flex gap-3 items-center  cursor-pointer relative">
              <div onClick={() => setShowUser(!showUser)} className="flex">
                <FaUser />
                <FaCaretDown />
              </div>
              {showUser && (
                <motion.ul
                  initial={{ y: 30, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.5 }}
                  className="absolute top-6 -left-20 z-50 bg-primeColor w-32 text-[#767676] h-auto p-4 pb-6"
                >
                  {userInfo ? (
                    <Link
                      to={
                        userInfo.isAdmin || userInfo.isSeller
                          ? "/account/profile"
                          : "userdashboard"
                      }
                    >
                      <li className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer">
                        {userInfo.name}
                      </li>
                    </Link>
                  ) : (
                    <>
                      <Link to="/signin">
                        <li className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer">
                          Login
                        </li>
                      </Link>
                      <Link onClick={() => setShowUser(false)} to="/signup">
                        <li className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer">
                          Sign Up
                        </li>
                      </Link>
                    </>
                  )}
                </motion.ul>
              )}
              <Link to="/cart">
                <div className="relative">
                  <FaShoppingCart />
                  {cartItems.length > 0 && (
                    <span className="absolute font-titleFont -top-2 -right-2 text-xs w-4 h-4 flex items-center justify-center rounded-full bg-primeColor text-white">
                      {cartItems.reduce((a, c) => a + c.qty, 0)}
                    </span>
                  )}
                </div>
              </Link>
            </div>
          </div>
          <div className="relative w-full lg:w-[600px] h-[50px] text-base text-primeColor bg-white flex items-center gap-2 justify-between px-6 rounded-xl">
            <input
              className="flex-1 h-full outline-none placeholder:text-[#C4C4C4] placeholder:text-[14px]"
              type="text"
              onChange={handleSearch}
              value={searchQuery}
              placeholder="Search your products here"
            />
            <FaSearch className="w-5 h-5" />
            {searchQuery && (
              <div
                className={`w-full mx-auto h-96 bg-white top-16 absolute left-0 z-50 overflow-y-scroll shadow-2xl scrollbar-hide cursor-pointer`}
              >
                {searchQuery &&
                  filteredProducts.map((item) => (
                    <div
                      onClick={() =>
                        navigate(
                          `/product/${item.productName
                            .toLowerCase()
                            .split(" ")
                            .join("")}`,
                          {
                            state: {
                              item: item,
                            },
                          }
                        ) &
                        setShowSearchBar(true) &
                        setSearchQuery("")
                      }
                      key={item._id}
                      className="max-w-[600px] h-28 bg-gray-100 mb-3 flex items-center gap-3"
                    >
                      <img className="w-24" src={item.img} alt="productImg" />
                      <div className="flex flex-col gap-1">
                        <p className="font-semibold text-lg">
                          {item.productName}
                        </p>
                        <p className="text-xs">{item.des}</p>
                        <p className="text-sm">
                          Price:{" "}
                          <span className="text-primeColor font-semibold">
                            ${item.price}
                          </span>
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
          <div className="hidden lg:flex gap-4 items-center  cursor-pointer relative">
            <div onClick={() => setShowUser(!showUser)} className="flex">
              <FaUser />
              <FaCaretDown />
            </div>
            {showUser && (
              <motion.ul
                initial={{ y: 30, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="absolute top-6 -left-20 z-50 bg-primeColor w-36 text-[#767676] h-auto p-4 pb-6"
              >
                {userInfo ? (
                  <Link
                    to={
                      userInfo.isAdmin || userInfo.isSeller
                        ? "/account/profile"
                        : "userdashboard"
                    }
                  >
                    <li className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer">
                      {userInfo.name}
                    </li>
                  </Link>
                ) : (
                  <>
                    <Link to="/signin">
                      <li className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer">
                        Login
                      </li>
                    </Link>
                    <Link onClick={() => setShowUser(false)} to="/signup">
                      <li className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer">
                        Sign Up
                      </li>
                    </Link>
                  </>
                )}
              </motion.ul>
            )}
            <Link to="/cart">
              <div className="relative">
                <FaShoppingCart />
                {cartItems.length > 0 && (
                  <span className="absolute font-titleFont top-3 -right-2 text-xs w-4 h-4 flex items-center justify-center rounded-full bg-primeColor text-white">
                    {cartItems.reduce((a, c) => a + c.qty, 0)}
                  </span>
                )}
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderBottom;
