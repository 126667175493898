import React from "react";
import Heading from "../Products/Heading";
import Product from "../Products/Product";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Loading from "../../Loading";
import MessageBox from "../../MessageBox";

const SpecialOffers = () => {
  const productList = useSelector((state) => state.productList);
  const { loading, error, products } = productList;
  return (
    <div className="w-full pb-20">
      <div className="flex items-center justify-between w-full">
        <Heading heading="Products" />
        <div className="flex items-center hover:text-[#04d74b]">
          <Link to="shop">See All</Link>{" "}
          <span class=" rtl:-scale-x-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-5 h-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              />
            </svg>
          </span>
        </div>
      </div>

      {loading ? (
        <Loading />
      ) : error ? (
        <MessageBox variant="text-red-500 bg-red-100 my-1 ">{error}</MessageBox>
      ) : (
        <div className="w-full grid grid-cols-1 sm:grid-cols-2 lgl:grid-cols-4 xl:grid-cols-5 gap-2 md:gap-10">
          {products.slice(0, 15).map((product) => (
            <Product key={product.slug} product={product} />
          ))}
        </div>
      )}
    </div>
  );
};

export default SpecialOffers;
