import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { ORDER_PAY_RESET } from '../constants/orderContants';
import { detailsOrder, payOrder } from '../actions/orderActions';
import Swal from 'sweetalert2';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import { PaystackButton } from 'react-paystack';

function OrderDetails() {
  const params = useParams();
  const orderId = params.id;

  const orderDetails = useSelector((state) => state.orderDetails);
  const { order, loading, error } = orderDetails;

  const orderPay = useSelector((state) => state.orderPay);
  const { success: successPay } = orderPay;
  const dispatch = useDispatch();

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  useEffect(() => {
    // const addPaystack = async () => {
    // 	const { data } = await Axios.get("/api/config/paystack");
    // 	setPublickey({ data });
    // };

    if (!order || successPay || (order && order._id !== orderId)) {
      dispatch({ type: ORDER_PAY_RESET });
      dispatch(detailsOrder(orderId));
    } else {
      if (!order.isPaid) {
        // addPaystack();
      }
    }
  }, [dispatch, order, successPay, orderId]);

  const config = {
    reference: new Date().getTime().toString(),
    publicKey: "pk_live_8da5ad6a67eaa76b7bdaadf69c2fe526f420f345",
  };

  const name = userInfo.name;
  console.log(name);

  // you can call this function anything
  const handlePaystackSuccessAction = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    dispatch(payOrder(order, reference));
    Swal({
      title: "Done!",
      text: "Payment Successful, check your email for confirmation",
      icon: "success",
      showConfirmButton: false,
      timer: 2000,
    });
  };

  // you can call this function anything
  const handlePaystackCloseAction = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    Swal.fire({
      title: "Oops...",
      text: "Payment not completed",
      icon: "error",
    });
    console.log("closed");
  };

  const componentProps = {
    ...config,
    metadata: {
      name,
    },
    text: (
      <>
        {" "}
        Pay Now {"  "}
        <i className="fa fa-money"></i>
      </>
    ),
    onSuccess: (reference) => handlePaystackSuccessAction(reference),
    onClose: handlePaystackCloseAction,
  };

  return (
    <div class="main-wrapper">
      {loading ? (
        <LoadingBox></LoadingBox>
      ) : error ? (
        <MessageBox variant="text-red-500 bg-red-100 my-1 ">
          {error}
        </MessageBox>
      ) : (
        <main class="main users chart-page container" id="skip-target">
          <section className="shop checkout">
            <div class="container">
              <h2 class="main-title">Order {order._id} </h2>
              <div class="row">
                <div class="col-lg-8 col-12">
                  <div class="card card-body ">
                    <h2 class="main-title">Shipping</h2>
                    <p>
                      <strong>Name:</strong> {order.shippingAddress.fullName}
                      <br />
                      <strong>Address:</strong> {order.shippingAddress.address},{" "}
                      {order.shippingAddress.city},{" "}
                      {order.shippingAddress.postalCode},{" "}
                      {order.shippingAddress.country}
                    </p>
                    {order.isDelivered ? (
                      <MessageBox variant="success">
                        Delivered at {order.deliveredAt}{" "}
                      </MessageBox>
                    ) : (
                      <MessageBox variant="danger">Not Delivered </MessageBox>
                    )}
                  </div>

                  <div class="card card-body mt-4">
                    <h2 class="main-title">Payment</h2>
                    <p>
                      <strong>Method:</strong> {order.paymentMethod}
                    </p>
                    {order.isPaid ? (
                      <MessageBox variant="success">
                        Paid at {order.paidAt}{" "}
                      </MessageBox>
                    ) : (
                      <MessageBox variant="danger">Not Paid </MessageBox>
                    )}
                  </div>

                  <div className="card card-body mt-4">
                    <h2 className="main-title">Order Items</h2>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="users-table item-wrapper">
                          <table className="posts-table">
                            {/* <thead>
                                                                <tr className="users-table-info">
                                                                    <th>Thumbnails</th>
                                                                    <th>NAME</th>
                                                                    <th>EDIT</th>
                                                                    <th>DELETE</th>
                                                                </tr>
                                                            </thead> */}
                            <tbody>
                              {order.orderItems.map((item) => (
                                <tr key={item.product}>
                                  <td>
                                    <label className="users-table__checkbox">
                                      <div className="categories-table-img">
                                        <picture>
                                          <source
                                            srcset={item.image}
                                            type="image/webp"
                                          />
                                          <img
                                            src={item.image}
                                            alt={item.name}
                                          />
                                        </picture>
                                      </div>
                                    </label>
                                  </td>

                                  <td>
                                    <div className="pages-table-img">
                                      <Link to={`/product/${item.slug}`}>
                                        {item.name}
                                      </Link>
                                    </div>
                                  </td>

                                  <td>
                                    <span className="badge-active">
                                      {item.qty} x ₦{item.price} = ₦
                                      {item.qty * item.price}
                                    </span>
                                  </td>
                                  <td>
                                    <div class="top-cat-list__title">
                                      Total Amount
                                    </div>
                                    <div class="top-cat-list__subtitle">
                                      ₦{" "}
                                      {order.orderItems.reduce(
                                        (a, c) => a + c.price * c.qty,
                                        0
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-12">
                  <div className="card order-details">
                    <div className="single-widget">
                      <h2>order TOTALS</h2>
                      <div className="content">
                        <ul>
                          <li>
                            Items<span>₦ {order.itemsPrice.toFixed(2)}</span>
                          </li>

                          <li className="last">
                            <strong> Order Total</strong>
                            <span>₦ {order.totalPrice.toFixed(2)}</span>
                          </li>
                          {!order.isPaid && (
                            <li>
                              <div className="button5">
                                <PaystackButton
                                  {...componentProps}
                                  email={userInfo.email}
                                  amount={order.totalPrice.toFixed(2) * 100}
                                  className="primary block btn"
                                />
                              </div>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      )}
    </div>
  );
}

export default OrderDetails;
