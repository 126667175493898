import React from "react";
import { Link, useNavigate } from "react-router-dom";


const Product = (props) => {
	const { product } = props;
	const navigate = useNavigate();
  return (
    <article
      key={product.id}
      className="rounded-xl bg-white p-3 my-2 shadow-md hover:shadow-xl hover:transform hover:scale-105 duration-300 md:w-[14rem]"
    >
      <Link to={`/product/${product.slug}`}>
        <div className="overflow-hidden rounded-xl h-[12rem]">
          <img src={product.image} alt={product.name} />
        </div>
        <div className="mt-1 p-2">
          <h2 className="font-medium text-slate-700">{product.name}</h2>
          <p className=" text-sm text-slate-400">{product.category}</p>
        </div>
      </Link>

      <div className=" p-2 flex items-end justify-between">
        <p className="text-base font-medium text-gray-900">
          ₦{product.price.toLocaleString()}
        </p>
        {product.countInStock > 0 ? (
          <div className="flex items-center space-x-1.5 rounded-lg bg-[#04d74b] px-2 py-1.5 text-white duration-100 hover:bg-[#048654]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="h-4 w-4"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
              />
            </svg>

            <button
              onClick={() => {
                navigate(`/cart/${product._id}?qty=1`);
              }}
              className="text-xs"
            >
              Add to cart
            </button>
          </div>
        ) : (
          <div className="flex items-center space-x-1.5 rounded-lg bg-[#04d74b] px-2 py-1.5 text-white duration-100 hover:bg-[#048654]">
            <button className="text-xs">Out of Stock</button>
          </div>
        )}
      </div>
    </article>
  );
};

export default Product;
