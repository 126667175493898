import React from "react";

function ReviewLoader() {
  return (
    <div class="flex w-full items-center mx-auto overflow-hidden  animate-pulse p-1 lg:p-3">
      <div class="w-[4.3rem] h-16 bg-gray-300  rounded-full "></div>

      <div class="w-full p-4 md:p-4">
        <h1 class="w-40 h-2 bg-gray-200 rounded-lg "></h1>

        <p class="w-48 h-2 mt-2 bg-gray-200 rounded-lg "></p>

        <div class="flex mt-2 item-center gap-x-2">
          <p class="w-5 h-2 bg-gray-200 rounded-lg "></p>
          <p class="w-5 h-2 bg-gray-200 rounded-lg "></p>
          <p class="w-5 h-2 bg-gray-200 rounded-lg "></p>
          <p class="w-5 h-2 bg-gray-200 rounded-lg "></p>
          <p class="w-5 h-2 bg-gray-200 rounded-lg "></p>
        </div>

        <div class="flex justify-between mt-3 item-center">
          <h1 class="w-10 h-2 bg-gray-200 rounded-lg "></h1>

          <div class="h-4 bg-gray-200 rounded-lg w-28 "></div>
        </div>
      </div>
    </div>
  );
}

export default ReviewLoader;
