import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import Pagination from "../../components/pageProps/shopPage/Pagination";
import ProductBanner from "../../components/pageProps/shopPage/ProductBanner";
import { listProducts } from "../../actions/productActions";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import NavTitle from "../../components/pageProps/shopPage/shopBy/NavTitle";
import Rating from "../../components/Rating";
import { ratings } from "../../utils/ratings";
import { prices } from "../../utils/prices";
import MessageBox from "../../components/MessageBox";
import LoadingBox from "../../components/LoadingBox";
const Shop = () => {
  const [itemsPerPage, setItemsPerPage] = useState(12);
  const itemsPerPageFromBanner = (itemsPerPage) => {
    setItemsPerPage(itemsPerPage);
  };

  // const navigate = useNavigate();
  // const {
  //   name = "all",
  //   category = "all",
  //   min = 0,
  //   max = 0,
  //   rating = 0,
  //   order = "newest",
  //   pageNumber = 1,
  // } = useParams();
  // const dispatch = useDispatch();

  // const productList = useSelector((state) => state.productList);
  // const { loading, error, products, page, pages } = productList;

  // const productCategoryList = useSelector((state) => state.productCategoryList);
  // const {
  //   loading: loadingCategories,
  //   error: errorCategories,
  //   categories,
  // } = productCategoryList;

  // useEffect(() => {
  //   dispatch(
  //     listProducts({
  //       pageNumber,
  //       name: name !== "all" ? name : "",
  //       category: category !== "all" ? category : "",
  //       min,
  //       max,
  //       rating,
  //       order,
  //     })
  //   );
  // }, [category, dispatch, max, min, name, order, rating, pageNumber]);

  // const getFilterUrl = (filter) => {
  //   const filterPage = filter.page || pageNumber;
  //   const filterCategory = filter.category || category;
  //   const filterName = filter.name || name;
  //   const filterRating = filter.rating || rating;
  //   const sortOrder = filter.order || order;
  //   const filterMin = filter.min ? filter.min : filter.min === 0 ? 0 : min;
  //   const filterMax = filter.max ? filter.max : filter.max === 0 ? 0 : max;
  //   return `/search/category/${filterCategory}/name/${filterName}/min/${filterMin}/max/${filterMax}/rating/${filterRating}/order/${sortOrder}/pageNumber/${filterPage}`;
  // };

  return (
    <div className="max-w-container mx-auto px-3 md:px-20">
      <Breadcrumbs title="Products" />
      {/* ================= Products Start here =================== */}
      <div className="w-full h-full flex pb-20 gap-10">
        <div className="w-[16%] lgl:w-[20%] hidden mdl:inline-flex h-full">
          {/* <ShopSideNav /> */}
          <div className="w-full flex flex-col gap-6">
            <div className="w-full">
              <NavTitle title="Shop by Category" />
              {/* <div>
                {loadingCategories ? (
                  <LoadingBox></LoadingBox>
                ) : errorCategories ? (
                  <MessageBox variant="text-red-500 bg-red-100 my-1 ">
                    {errorCategories}
                  </MessageBox>
                ) : (
                  <ul className="flex flex-col gap-4 text-sm lg:text-base text-[#767676]">
                    <li className="border-b-[1px] border-b-[#F0F0F0] pb-2 flex items-center justify-between">
                      <Link
                        className={"all" === category ? "active" : ""}
                        to={getFilterUrl({ category: "all" })}
                      >
                        Any
                      </Link>
                    </li>
                    {categories.map((c) => (
                      <li
                        key={c}
                        className="border-b-[1px] border-b-[#F0F0F0] pb-2 flex items-center justify-between"
                      >
                        <Link
                          className={c === category ? "active" : ""}
                          to={getFilterUrl({ category: c })}
                        >
                          {c}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </div> */}
            </div>
            <div className="cursor-pointer">
              <NavTitle title="Shop by Price" />
              {/* <div className="font-titleFont">
                <ul className="flex flex-col gap-4 text-sm lg:text-base text-[#767676]">
                  {prices.map((p) => (
                    <li
                      key={p.name}
                      className="border-b-[1px] border-b-[#F0F0F0] pb-2 flex items-center gap-2 hover:text-primeColor hover:border-gray-400 duration-300"
                    >
                      <Link
                        to={getFilterUrl({ min: p.min, max: p.max })}
                        className={
                          `${p.min.toFixed(2)}-${p.max.toFixed(2)}` ===
                          `${min.toFixed(2)}-${max.toFixed(2)}`
                            ? "active"
                            : ""
                        }
                      >
                        {p.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div> */}
            </div>
            {/* <div className="cursor-pointer">
              <NavTitle title="Shop by Review" />
              <div className="font-titleFont">
                <ul className="flex flex-col gap-4 text-sm lg:text-base text-[#767676]">
                  {ratings.map((r) => (
                    <li
                      key={r.name}
                      className="border-b-[1px] border-b-[#F0F0F0] pb-2 flex items-center gap-2 hover:text-primeColor hover:border-gray-400 duration-300"
                    >
                      <Link
                        to={getFilterUrl({ rating: r.rating })}
                        className={
                          `${r.rating}` === `${rating}` ? "active" : ""
                        }
                      >
                        <Rating rating={r.rating} caption={" & up"}></Rating>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div> */}
          </div>
        </div>
        <div className="w-full mdl:w-[80%] lgl:w-[75%] h-full flex flex-col gap-10">
          <ProductBanner itemsPerPageFromBanner={itemsPerPageFromBanner} />
          <Pagination itemsPerPage={itemsPerPage} />
        </div>
      </div>
      {/* ================= Products End here ===================== */}
    </div>
  );
};

export default Shop;
