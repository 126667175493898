import React, { useEffect } from 'react';
import Slider from 'react-slick';
import Heading from '../Products/Heading';
import Product from '../Products/Product';

import SampleNextArrow from './SampleNextArrow';
import SamplePrevArrow from './SamplePrevArrow';
import { useDispatch, useSelector } from 'react-redux';
import { hotdealProducts } from '../../../actions/productActions';
import Loading from '../../Loading';
import MessageBox from '../../MessageBox';

const Hotdeals = () => {
	const dispatch = useDispatch();
	const productList = useSelector((state) => state.productList);
	const { loading, error, products } = productList;

	useEffect(() => {
		dispatch(hotdealProducts({}));
	}, [dispatch]);
	const settings = {
		infinite: true,
		speed: 500,
		slidesToShow: 5,
		slidesToScroll: 1,
		nextArrow: <SampleNextArrow />,
		prevArrow: <SamplePrevArrow />,
		responsive: [
			{
				breakpoint: 1025,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
					infinite: true,
				},
			},
			{
				breakpoint: 769,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					infinite: true,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
				},
			},
		],
	};
	return (
    <div className="w-full pb-16">
      <Heading heading="Hot Deals" />
      {loading ? (
        <Loading />
      ) : error ? (
        <MessageBox variant="text-red-500 bg-red-100 my-1 ">{error}</MessageBox>
      ) : (
        <Slider {...settings}>
          {products
            .filter((product) => product.category === "hotdeals")
            .map((hotdeals, i) => (
              <div key={i} className="px-2">
                <Product product={hotdeals} />
              </div>
            ))}
        </Slider>
      )}
    </div>
  );
};

export default Hotdeals;
