import React from 'react';

function FAQs() {
	return (
		<div className="grid grid-cols-1 lg:grid-cols-12 items-center">
			<div className="md:col-span-8">
				<div class="relative mx-auto w-full py-16 px-5 font-sans text-gray-800 md:px-20 md:max-w-screen-lg lg:py-24">
					<h2 class="mb-5 text-center font-sans text-4xl sm:text-5xl font-bold">
						Frequently asked Questions
					</h2>
					<p class="mb-12 text-center text-lg text-gray-600">
						We have written down answers to some of the frequently asked
						questions. But, if you still have any queries, feel free to ping us
						on chat.
					</p>
					<ul class="space-y-4">
						<li class="text-left">
							<label
								for="accordion-1"
								class="relative flex flex-col rounded-md border border-gray-100 shadow-md"
							>
								<input
									class="peer hidden"
									type="checkbox"
									id="accordion-1"
									checked
								/>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									class="absolute right-0 top-4 ml-auto mr-5 h-4 text-gray-500 transition peer-checked:rotate-180"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									stroke-width="2"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										d="M19 9l-7 7-7-7"
									/>
								</svg>
								<div class="relative ml-4 cursor-pointer select-none items-center py-4 pr-12">
									<h3 class="text-sm text-gray-600 lg:text-base">
										What is COBAI?
									</h3>
								</div>
								<div class="max-h-0 overflow-hidden transition-all duration-500 peer-checked:max-h-96">
									<div class="p-5">
										<p class="text-sm">
											COBAI is Africa’s B2B/B2C classified listing food and
											agro-allied online Marketplace, that connects Africa
											businesses in the Agricultural sector, giving them an
											enabling system that helps them close legitimate deals and
											same time make rock bottom sales both locally and
											internationally.
										</p>
									</div>
								</div>
							</label>
						</li>

						<li class="text-left">
							<label
								for="accordion-2"
								class="relative flex flex-col rounded-md border border-gray-100 shadow-md"
							>
								<input class="peer hidden" type="checkbox" id="accordion-2" />
								<svg
									xmlns="http://www.w3.org/2000/svg"
									class="absolute right-0 top-4 ml-auto mr-5 h-4 text-gray-500 transition peer-checked:rotate-180"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									stroke-width="2"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										d="M19 9l-7 7-7-7"
									/>
								</svg>
								<div class="relative ml-4 cursor-pointer select-none items-center py-4 pr-12">
									<h3 class="text-sm text-gray-600 lg:text-base">
										How does it work?
									</h3>
								</div>
								<div class="max-h-0 overflow-hidden transition-all duration-500 peer-checked:max-h-96">
									<div class="p-5">
										<p class="text-sm">
											All that you need to do is to register for a FREE account
											to get started. Simply follow the steps below to complete
											your registration today! <br />
											<strong>
												- Click Signup button on the right top corner of the
												homepage.
											</strong>
											<br />
											<strong>- Click Create a new account.</strong>
											<br />
											<strong>
												- Fill up the form to complete the registration.
											</strong>
										</p>
									</div>
								</div>
							</label>
						</li>

						<li class="text-left">
							<label
								for="accordion-3"
								class="relative flex flex-col rounded-md border border-gray-100 shadow-md"
							>
								<input class="peer hidden" type="checkbox" id="accordion-3" />
								<svg
									xmlns="http://www.w3.org/2000/svg"
									class="absolute right-0 top-4 ml-auto mr-5 h-4 text-gray-500 transition peer-checked:rotate-180"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									stroke-width="2"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										d="M19 9l-7 7-7-7"
									/>
								</svg>
								<div class="relative ml-4 cursor-pointer select-none items-center py-4 pr-12">
									<h3 class="text-sm text-gray-600 lg:text-base">
										Do I need a separate account to buy or sell on COBAI?
									</h3>
								</div>
								<div class="max-h-0 overflow-hidden transition-all duration-500 peer-checked:max-h-96">
									<div class="p-5">
										<p class="text-sm">
											No. You only need one account in order to buy and sell.
										</p>
									</div>
								</div>
							</label>
						</li>

						<li class="text-left">
							<label
								for="accordion-4"
								class="relative flex flex-col rounded-md border border-gray-100 shadow-md"
							>
								<input class="peer hidden" type="checkbox" id="accordion-4" />
								<svg
									xmlns="http://www.w3.org/2000/svg"
									class="absolute right-0 top-4 ml-auto mr-5 h-4 text-gray-500 transition peer-checked:rotate-180"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									stroke-width="2"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										d="M19 9l-7 7-7-7"
									/>
								</svg>
								<div class="relative ml-4 cursor-pointer select-none items-center py-4 pr-12">
									<h3 class="text-sm text-gray-600 lg:text-base">
										I forgot my password? Can I reset it?
									</h3>
								</div>
								<div class="max-h-0 overflow-hidden transition-all duration-500 peer-checked:max-h-96">
									<div class="p-5">
										<p class="text-sm">
											To retrieve your password, go to the Login page and click
											on Forgot Password. You will be asked to fill in and
											submit your email address. After doing so, you will
											receive an email which enables you to reset your password.
										</p>
									</div>
								</div>
							</label>
						</li>
						<li class="text-left">
							<label
								for="accordion-5"
								class="relative flex flex-col rounded-md border border-gray-100 shadow-md"
							>
								<input class="peer hidden" type="checkbox" id="accordion-5" />
								<svg
									xmlns="http://www.w3.org/2000/svg"
									class="absolute right-0 top-4 ml-auto mr-5 h-4 text-gray-500 transition peer-checked:rotate-180"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									stroke-width="2"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										d="M19 9l-7 7-7-7"
									/>
								</svg>
								<div class="relative ml-4 cursor-pointer select-none items-center py-4 pr-12">
									<h3 class="text-sm text-gray-600 lg:text-base">
										Can I change my email address?
									</h3>
								</div>
								<div class="max-h-0 overflow-hidden transition-all duration-500 peer-checked:max-h-96">
									<div class="p-5">
										<p class="text-sm">
											Yes. You need to:
											<br />
											<strong>
												- Login to your COBAI account and click My
												Account located at the right top corner of the homepage.
											</strong>
											<br />
											<strong>
												- Then click Account Settings from the left hand
												selection of your screen.
											</strong>
											<br />
											<strong>
												- Finally click Change Email located at the bottom right
												of the page to change your email address.
											</strong>
										</p>
									</div>
								</div>
							</label>
						</li>
						<li class="text-left">
							<label
								for="accordion-6"
								class="relative flex flex-col rounded-md border border-gray-100 shadow-md"
							>
								<input class="peer hidden" type="checkbox" id="accordion-6" />
								<svg
									xmlns="http://www.w3.org/2000/svg"
									class="absolute right-0 top-4 ml-auto mr-5 h-4 text-gray-500 transition peer-checked:rotate-180"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									stroke-width="2"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										d="M19 9l-7 7-7-7"
									/>
								</svg>
								<div class="relative ml-4 cursor-pointer select-none items-center py-4 pr-12">
									<h3 class="text-sm text-gray-600 lg:text-base">
										Do I need an account to sell on COBAI?
									</h3>
								</div>
								<div class="max-h-0 overflow-hidden transition-all duration-500 peer-checked:max-h-96">
									<div class="p-5">
										<p class="text-sm">
											Yes! You need to log into your account to create alisting.
											If you do not have an account, we advise you open up an
											account immediately to start enjoying benefits Platform
											has to offer.
										</p>
									</div>
								</div>
							</label>
						</li>
						<li class="text-left">
							<label
								for="accordion-7"
								class="relative flex flex-col rounded-md border border-gray-100 shadow-md"
							>
								<input class="peer hidden" type="checkbox" id="accordion-7" />
								<svg
									xmlns="http://www.w3.org/2000/svg"
									class="absolute right-0 top-4 ml-auto mr-5 h-4 text-gray-500 transition peer-checked:rotate-180"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									stroke-width="2"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										d="M19 9l-7 7-7-7"
									/>
								</svg>
								<div class="relative ml-4 cursor-pointer select-none items-center py-4 pr-12">
									<h3 class="text-sm text-gray-600 lg:text-base">
										Do I need to pay to post on COBAI?
									</h3>
								</div>
								<div class="max-h-0 overflow-hidden transition-all duration-500 peer-checked:max-h-96">
									<div class="p-5">
										<p class="text-sm">
											No upfront costs are required when posting on COBAI as we
											act as direct facilitators and Escrow of such deals to
											avoid fraud and we charge 10% of every successfully closed
											deal.
										</p>
									</div>
								</div>
							</label>
						</li>
						<li class="text-left">
							<label
								for="accordion-8"
								class="relative flex flex-col rounded-md border border-gray-100 shadow-md"
							>
								<input class="peer hidden" type="checkbox" id="accordion-8" />
								<svg
									xmlns="http://www.w3.org/2000/svg"
									class="absolute right-0 top-4 ml-auto mr-5 h-4 text-gray-500 transition peer-checked:rotate-180"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									stroke-width="2"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										d="M19 9l-7 7-7-7"
									/>
								</svg>
								<div class="relative ml-4 cursor-pointer select-none items-center py-4 pr-12">
									<h3 class="text-sm text-gray-600 lg:text-base">
										How do I take advantage of COBAI?
									</h3>
								</div>
								<div class="max-h-0 overflow-hidden transition-all duration-500 peer-checked:max-h-96">
									<div class="p-5">
										<p class="text-sm">
											We act as direct facilitators and Escrow of every deal to
											avoid fraud and we charge 10% of every successfully closed
											deal.
										</p>
									</div>
								</div>
							</label>
						</li>
					</ul>
				</div>
			</div>
			<div className="sm:col-span-4">
				<img className="img-fluid" src="img/faqs.png" alt="" />
			</div>
		</div>
	);
}

export default FAQs;
