import React, { useEffect, useState } from "react";
import { BsCheckCircleFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logoLight } from "../../assets/images";
import Axios from "axios";
import LoadingBox from "../../components/LoadingBox";
import MessageBox from "../../components/MessageBox";
import { USER_UPDATE_PROFILE_RESET, USER_UPDATE_RESET } from "../../constants/userConstants";
import { detailsUser, updateUserProfile, vendor } from "../../actions/userActions";

function VendorRegister() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const navigate = useNavigate();
  const [isSeller, setIsSeller] = useState(true);
  const [sellerCity, setSellerCity] = useState("");
  const [sellerProvince, setSellerProvince] = useState("");
  const [sellerCountry, setSellerCountry] = useState("");
  const [sellerStoreName, setSellerStoreName] = useState("");
  const [sellerStoreAddress, setSellerStoreAddress] = useState("");
  const [sellerPhoneNumber, setSellerPhoneNumber] = useState("");
  const [sellerBusinessType, setSellerBusinessType] = useState("");
  const [sellerCAC, setSellerCAC] = useState("");
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [errorUpload, setErrorUpload] = useState("");
  const [checked, setChecked] = useState(false);

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;
  const dispatch = useDispatch();

  const userUpdate = useSelector((state) => state.userUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = userUpdate;

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    const bodyFormData = new FormData();
    bodyFormData.append("image", file);
    setLoadingUpload(true);
    try {
      const { data } = await Axios.post("/api/uploads", bodyFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.token}`,
        },
      });
      setSellerCAC(data);
      setLoadingUpload(false);
    } catch (error) {
      setErrorUpload(error.message);
      setLoadingUpload(false);
    }
  };

  useEffect(() => {
    if (!userInfo) {
      navigate("/signin?redirect=/vendor");
    }
    if (!user) {
      dispatch({ type: USER_UPDATE_PROFILE_RESET });
      dispatch(detailsUser(userInfo._id));
    }
    if (successUpdate) {
		dispatch({ type: USER_UPDATE_RESET });		
		navigate("/account/profile");
		window.location.reload(false);
    }
  }, [dispatch, user, successUpdate, navigate, userInfo]);

  const submitHandler = (e) => {
    e.preventDefault();
    setIsSeller("true");

    dispatch(vendor({ _id: user._id, isSeller }));
    dispatch(
      updateUserProfile({
        userId: user._id,
        name: user.name,
        email: user.email,
        password: user.password,
        sellerCity,
        sellerProvince,
        sellerCountry,
        sellerStoreName,
        sellerStoreAddress,
        sellerPhoneNumber,
        sellerBusinessType,
        sellerCAC,
      })
    );
  };
  return (
    <div className="w-full h-screen flex items-center justify-start">
      <div className="hidden bg-slate-600 lg:inline-flex h-full text-white">
        <div className="w-[450px] h-full bg-primeColor px-10 flex flex-col gap-6 justify-center">
          <Link to="/">
            <img src={logoLight} alt="logoImg" className="w-28" />
          </Link>
          <div className="flex flex-col gap-1 -mt-1">
            <h1 className="font-titleFont text-xl font-medium">
              Get started for free
            </h1>

            <p className="text-base">
              Start selling today on our marketplace to grow your businesses.
            </p>
          </div>
          <div className="w-[300px] flex items-start gap-3">
            <span className="text-green-500 mt-1">
              <BsCheckCircleFill />
            </span>
            <p className="text-base text-gray-300">
              <span className="text-white font-semibold font-titleFont">
                Get started fast with COBAI
              </span>
              <br />
              By creating an account with our store, you will be able to move
              through the checkout process faster
            </p>
          </div>
          <div className="w-[300px] flex items-start gap-3">
            <span className="text-green-500 mt-1">
              <BsCheckCircleFill />
            </span>
            <p className="text-base text-gray-300">
              <span className="text-white font-semibold font-titleFont">
                Access all COBAI services
              </span>
              <br />
              Just with a click of the button you can access loads of COBAI's
              offerings seamlessly.
            </p>
          </div>
          <div className="w-[300px] flex items-start gap-3">
            <span className="text-green-500 mt-1">
              <BsCheckCircleFill />
            </span>
            <p className="text-base text-gray-300">
              <span className="text-white font-semibold font-titleFont">
                Trusted by online Shoppers
              </span>
              <br />
              Join the Bandwagon of Millions that take COBAI as their everyday
              shopping Plug and enjoy our juicy offers without fear of being
              Scammed.
            </p>
          </div>
          <div className="flex items-center justify-between mt-10">
            <Link to="/">
              <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
                © COBAI
              </p>
            </Link>
            <Link to="/terms">
              <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
                Terms
              </p>
            </Link>
            <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
              Privacy
            </p>
            <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
              Security
            </p>
          </div>
        </div>
      </div>
      <div className="w-full  h-full">
        <form
          onSubmit={submitHandler}
          className="mx-auto md:w-[650px] h-screen flex items-center justify-center"
        >
          <div className="px-6 py-4 w-full flex flex-col justify-center ">
            <div class="text-center my-4">
              <div class="flex justify-center mx-auto ">
                <svg
                  className="w-[7rem] h-[6rem]"
                  width="134"
                  height="134"
                  viewBox="0 0 134 134"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M67 11.1667C36.18 11.1667 11.1667 36.18 11.1667 67C11.1667 97.82 36.18 122.833 67 122.833C97.82 122.833 122.833 97.82 122.833 67C122.833 36.18 97.82 11.1667 67 11.1667ZM67 33.5C77.7758 33.5 86.5417 42.2658 86.5417 53.0417C86.5417 63.8175 77.7758 72.5833 67 72.5833C56.2242 72.5833 47.4583 63.8175 47.4583 53.0417C47.4583 42.2658 56.2242 33.5 67 33.5ZM67 111.667C55.6658 111.667 42.2658 107.088 32.7183 95.5867C42.4981 87.9136 54.5694 83.7433 67 83.7433C79.4306 83.7433 91.5019 87.9136 101.282 95.5867C91.7342 107.088 78.3342 111.667 67 111.667Z"
                    fill="#E7E7E7"
                  />
                </svg>
              </div>

              <h1 className=" text-3xl font-bold text-center text-[#000] ">
                Welcome to Cobai!
              </h1>

              <p className=" px-12 text-sm text-center text-gray-500 ">
                By creating an account with our store, you will be able to move
                through the checkout process faster.
              </p>
            </div>

            {loading && <LoadingBox />}
            {error && (
              <MessageBox variant="text-red-500 bg-red-100 my-1 ">
                {error}{" "}
              </MessageBox>
            )}
            <div className="flex flex-col gap-7">
              {/*Stor Name */}
              <div className="flex flex-col gap-.5">
                <p className="font-titleFont text-base font-semibold ml-5 py-1 text-gray-600">
                  Store Name
                </p>
                <input
                  value={sellerStoreName}
                  onChange={(e) => setSellerStoreName(e.target.value)}
                  className="w-full  placeholder:text-sm placeholder:tracking-wide p-4 text-base font-medium placeholder:font-normal rounded-xl border-[1px] border-gray-400 outline-none"
                  type="text"
                  placeholder="Enter Store Name"
                />
              </div>
              {/* Address */}
              <div className="flex flex-col gap-.5">
                <p className="font-titleFont text-base font-semibold ml-5 py-1 text-gray-600">
                  Address
                </p>
                <input
                  value={sellerStoreAddress}
                  onChange={(e) => setSellerStoreAddress(e.target.value)}
                  className="w-full  placeholder:text-sm placeholder:tracking-wide p-4 text-base font-medium placeholder:font-normal rounded-xl border-[1px] border-gray-400 outline-none"
                  type="text"
                  placeholder="road-001, house-115, example area"
                />
              </div>

              <div className="flex justify-between gap-x-2">
                {/* Phone Number */}
                <div className="flex flex-col gap-.5">
                  <p className="font-titleFont text-base font-semibold text-gray-600">
                    Phone Number
                  </p>
                  <input
                    required
                    value={sellerPhoneNumber}
                    onChange={(e) => setSellerPhoneNumber(e.target.value)}
                    className="w-full  placeholder:text-sm placeholder:tracking-wide p-4 text-base font-medium placeholder:font-normal rounded-xl border-[1px] border-gray-400 outline-none"
                    type="text"
                    placeholder="008801234567891"
                  />
                </div>
                {/* City */}
                <div className="flex flex-col gap-.5">
                  <p className="font-titleFont text-base font-semibold text-gray-600">
                    City
                  </p>
                  <input
                    value={sellerCity}
                    onChange={(e) => setSellerCity(e.target.value)}
                    className="w-full  placeholder:text-sm placeholder:tracking-wide p-4 text-base font-medium placeholder:font-normal rounded-xl border-[1px] border-gray-400 outline-none"
                    type="text"
                    placeholder="Your city"
                  />
                </div>
              </div>

              <div className="flex justify-between gap-x-2">
                {/* Zip code */}
                <div className="flex flex-col gap-.5">
                  <p className="font-titleFont text-base font-semibold text-gray-600">
                    State
                  </p>
                  <input
                    value={sellerProvince}
                    onChange={(e) => setSellerProvince(e.target.value)}
                    className="w-full  placeholder:text-sm placeholder:tracking-wide p-4 text-base font-medium placeholder:font-normal rounded-xl border-[1px] border-gray-400 outline-none"
                    type="text"
                    placeholder="Your State"
                    required
                  />
                </div>
                {/* Country */}
                <div className="flex flex-col gap-.5">
                  <p className="font-titleFont text-base font-semibold text-gray-600">
                    Country
                  </p>
                  <input
                    required
                    value={sellerCountry}
                    onChange={(e) => setSellerCountry(e.target.value)}
                    className="w-full  placeholder:text-sm placeholder:tracking-wide p-4 text-base font-medium placeholder:font-normal rounded-xl border-[1px] border-gray-400 outline-none"
                    type="text"
                    placeholder="Your country"
                  />
                </div>
              </div>

              <div className="flex flex-col gap-.5">
                <div class="relative">
                  <select
                    value={sellerBusinessType}
                    onChange={(e) => setSellerBusinessType(e.target.value)}
                    className="w-full  placeholder:text-sm placeholder:tracking-wide p-4 text-base font-medium placeholder:font-normal rounded-xl border-[1px] border-gray-400 outline-none"
                  >
                    <option value="">Business Type</option>
                    <option value="restaurant">RESTAURANT</option>
                    <option value="frozen">FROZEN FOODS</option>
                    <option value="florist">FLORIST</option>
                    <option value="friuts">FRUITS & VEGETABLES</option>
                    <option value="agro">FARM PRODUCE</option>
                    <option value="livestock">LIVESTOCK</option>
                    <option value="agro-llied">AGRO-ALLIED</option>
                    <option value="kitchen">KITCHEN ACCESSORIES</option>
                    <option value="grocery">GROCERY</option>
                    <option value="pastry">PASTRY</option>
                    <option value="ready-meals">READY MEALS</option>
                  </select>
                  <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4 text-gray-700">
                    <svg
                      class="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              </div>

              <div className="row py-2">
                <div className="col-sm-6">
                  <label htmlFor="imageFile">
                    CAC/Other Authorized Document
                  </label>

                  <input
                    type="file"
                    id="imageFile"
                    label="Choose Image"
                    onChange={uploadFileHandler}
                    className="form-control"
                  />

                  {loadingUpload && <LoadingBox></LoadingBox>}
                  {errorUpload && (
                    <MessageBox variant="text-red-500 bg-red-100 my-1 ">
                      {errorUpload}
                    </MessageBox>
                  )}
                </div>
              </div>
              {/* Checkbox */}
              <div className="flex items-start mdl:items-center gap-2">
                <input
                  onChange={() => setChecked(!checked)}
                  className="w-4 h-4 mt-1 mdl:mt-0 cursor-pointer"
                  type="checkbox"
                />
                <p className="text-sm text-primeColor">
                  I agree to the COBAI{" "}
                  <span className="text-green-600">Terms of Service </span>and{" "}
                  <span className="text-green-600">Privacy Policy</span>.
                </p>
              </div>

              <button
                type="submit"
                className={`${
                  checked
                    ? "w-full bg-green-500   text-white font-semibold transition duration-200 hover:bg-[#757E7E] cursor-pointer"
                    : "bg-gray-500 hover:bg-gray-500 hover:text-gray-200 cursor-none rounded-xl p-3"
                } w-full text-gray-200 text-base font-medium rounded-xl p-3  hover:text-white duration-300`}
              >
                Create Account
              </button>

              <p className="text-sm text-center font-titleFont font-semibold">
                I have an Account?{" "}
                <Link to={`/signin`}>
                  <span className="hover:text-green-600 text-red-500 duration-300">
                    Register
                  </span>
                </Link>
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default VendorRegister;
