import React from 'react';
import Banner from '../../components/Banner/Banner';
import BannerBottom from '../../components/Banner/BannerBottom';
import BecomeVendor from '../../components/home/BecomeVendor/BecomeVendor';
import Hotdeals from '../../components/home/Hotdeals/Hotdeals';
import Sale from '../../components/home/Sale/Sale';
import SpecialOffers from '../../components/home/SpecialOffers/SpecialOffers';


const Home = () => {
	return (
		<div className="w-full mx-auto">
			<div className="mx-auto lg:w-[70rem]">
				<Banner />
			</div>

			<BannerBottom />
			<div className="md:max-w-container mx-auto px-3 md:px-20">
				<Sale />
				<Hotdeals />
				<BecomeVendor />
				<SpecialOffers />
			</div>
		</div>
	);
};

export default Home;
