import React from "react";

function Loading() {
  return (
    <section>
      <div class="container px-4  mx-auto animate-pulse">
        <div class="grid grid-cols-2 gap-3 sm:gap-8 mt-5  sm:grid-cols-3 lg:grid-cols-5">
          <div class="w-full ">
            <div class="w-full h-40 lg:h-48 bg-gray-300 rounded-lg  "></div>

            <h1 class="w-full h-2 mt-4 bg-gray-200 rounded-lg "></h1>
            <p class="w-24 h-2 mt-4 bg-gray-200 rounded-lg "></p>
          </div>

          <div class="w-full ">
            <div class="w-full h-40 lg:h-48 bg-gray-300 rounded-lg  "></div>

            <h1 class="w-full h-2 mt-4 bg-gray-200 rounded-lg "></h1>
            <p class="w-24 h-2 mt-4 bg-gray-200 rounded-lg "></p>
          </div>

          <div class="w-full hidden sm:block ">
            <div class="w-full h-40 lg:h-48 bg-gray-300 rounded-lg  "></div>

            <h1 class="w-full h-2 mt-4 bg-gray-200 rounded-lg "></h1>
            <p class="w-24 h-2 mt-4 bg-gray-200 rounded-lg "></p>
          </div>
          <div class="w-full hidden lg:block ">
            <div class="w-full h-40 lg:h-48 bg-gray-300 rounded-lg  "></div>

            <h1 class="w-full h-2 mt-4 bg-gray-200 rounded-lg "></h1>
            <p class="w-24 h-2 mt-4 bg-gray-200 rounded-lg "></p>
          </div>
          <div class="w-full hidden lg:block ">
            <div class="w-full h-40 lg:h-48 bg-gray-300 rounded-lg  "></div>

            <h1 class="w-full h-2 mt-4 bg-gray-200 rounded-lg "></h1>
            <p class="w-24 h-2 mt-4 bg-gray-200 rounded-lg "></p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Loading;
