import React, { useEffect, useState } from "react";
import {
  Link,
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useSelector } from "react-redux";

function DashboardLayout() {
  const location = useLocation();
  const [toggle, setToggle] = useState(false);
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <header className="bg-slate-200 z-[100] font-[Lato] sticky top-0">
        <div className="lg:hidden bg-slate-800 items-center  px-3 flex py-5 ">
          {toggle ? (
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                setToggle(false);
              }}
              className="text-gray-100  hover:text-gray-600  focus:outline-none focus:text-gray-600 "
              aria-label="toggle menu"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6 text-white"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          ) : (
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                setToggle(true);
              }}
              className="text-white bg-gray-600 rounded-full p-2  hover:text-gray-600  focus:outline-none focus:text-gray-600 "
              aria-label="toggle menu"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          )}
          <div className="flex justify-center w-full">
            <Link to="/" className="">
              <img className="h-11 w-auto" src="../cobaiLight.png" alt="" />
            </Link>
          </div>
        </div>

        {/* toggle opaq backgroud */}
        <div
          onClick={(e) => {
            e.preventDefault();
            setToggle(false);
          }}
          class={`${
            toggle
              ? "translate-x-0 opacity-100 "
              : "opacity-0 -translate-x-full"
          } lg:hidden absolute inset-x-0 z-20 w-full top-0 h-screen transition-all duration-300 ease-in-out bg-slate-800/50 `}
        ></div>
        <div
          class={`${
            toggle
              ? "translate-x-0 opacity-100 "
              : "opacity-0 -translate-x-full"
          } lg:hidden absolute inset-x-0 z-20 w-[16rem] top-0 py-5 px-6 duration-300 ease-in-out bg-gray-700 text-white transition-all space-y-3`}
        >
          <div className="flex flex-col   pb-14">
            <div className=" border-b border-dashed border-[#9A9FBF]  w-full  mb-5 pb-6">
              <Link to="/" className="">
                <img className="h-11 w-auto" src="../cobaiLight.png" alt="" />
              </Link>
            </div>

            <ul className=" space-y-2 ">
              <li className="relative">
                <NavLink
                  to="/account/overview"
                  className={({ isActive }) =>
                    isActive
                      ? "focus:bg-slate-600 hover:bg-slate-600 flex w-full space-x-2 rounded-md px-3 py-4 font-semibold focus:outline-none"
                      : "focus:bg-slate-600 hover:bg-slate-600 flex w-full space-x-2 rounded-md px-3 py-4 text-gray-300 focus:outline-none"
                  }
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                      />
                    </svg>
                  </span>
                  <span className="">Overview</span>
                  {location.pathname === "/account/overview" && (
                    <svg
                      className="text-slate-200 absolute -right-1 -top-1/2 z-10 hidden h-32 w-8 md:block"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="399.349 57.696 100.163 402.081"
                      width="1em"
                      height="4em"
                    >
                      <path
                        fill="currentColor"
                        d="M 499.289 57.696 C 499.289 171.989 399.349 196.304 399.349 257.333 C 399.349 322.485 499.512 354.485 499.512 458.767 C 499.512 483.155 499.289 57.696 499.289 57.696 Z"
                      />
                    </svg>
                  )}
                </NavLink>
              </li>

              <li className="relative">
                <NavLink
                  to="/account/productlist"
                  className={({ isActive }) =>
                    isActive
                      ? "focus:bg-slate-600 hover:bg-slate-600 flex w-full space-x-2 rounded-md px-3 py-4 font-semibold focus:outline-none"
                      : "focus:bg-slate-600 hover:bg-slate-600 flex w-full space-x-2 rounded-md px-3 py-4 text-gray-300 focus:outline-none"
                  }
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                      />
                    </svg>
                  </span>
                  <span className="">Products</span>
                  {location.pathname === "/account/productlist" && (
                    <svg
                      className="text-slate-200 absolute -right-1 -top-1/2 z-10 hidden h-32 w-8 md:block"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="399.349 57.696 100.163 402.081"
                      width="1em"
                      height="4em"
                    >
                      <path
                        fill="currentColor"
                        d="M 499.289 57.696 C 499.289 171.989 399.349 196.304 399.349 257.333 C 399.349 322.485 499.512 354.485 499.512 458.767 C 499.512 483.155 499.289 57.696 499.289 57.696 Z"
                      />
                    </svg>
                  )}
                </NavLink>
              </li>
              <li className="relative">
                <NavLink
                  to="/account/product/:id/edit"
                  className={({ isActive }) =>
                    isActive
                      ? "focus:bg-slate-600 hover:bg-slate-600 flex w-full space-x-2 rounded-md px-3 py-4 font-semibold focus:outline-none"
                      : "focus:bg-slate-600 hover:bg-slate-600 flex w-full space-x-2 rounded-md px-3 py-4 text-gray-300 focus:outline-none"
                  }
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                      />
                    </svg>
                  </span>
                  <span className="">Create Hotdeal</span>
                  {location.pathname === "/account/product/:id/edit" && (
                    <svg
                      className="text-slate-200 absolute -right-1 -top-1/2 z-10 hidden h-32 w-8 md:block"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="399.349 57.696 100.163 402.081"
                      width="1em"
                      height="4em"
                    >
                      <path
                        fill="currentColor"
                        d="M 499.289 57.696 C 499.289 171.989 399.349 196.304 399.349 257.333 C 399.349 322.485 499.512 354.485 499.512 458.767 C 499.512 483.155 499.289 57.696 499.289 57.696 Z"
                      />
                    </svg>
                  )}
                </NavLink>
              </li>
              <li className="relative">
                <NavLink
                  to="/account/orderlist"
                  className={({ isActive }) =>
                    isActive
                      ? "focus:bg-slate-600 hover:bg-slate-600 flex w-full space-x-2 rounded-md px-3 py-4 font-semibold focus:outline-none"
                      : "focus:bg-slate-600 hover:bg-slate-600 flex w-full space-x-2 rounded-md px-3 py-4 text-gray-300 focus:outline-none"
                  }
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                      />
                    </svg>
                  </span>
                  <span className="">Orders</span>
                  {location.pathname === "/account/orderlist" && (
                    <svg
                      className="text-slate-200 absolute -right-1 -top-1/2 z-10 hidden h-32 w-8 md:block"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="399.349 57.696 100.163 402.081"
                      width="1em"
                      height="4em"
                    >
                      <path
                        fill="currentColor"
                        d="M 499.289 57.696 C 499.289 171.989 399.349 196.304 399.349 257.333 C 399.349 322.485 499.512 354.485 499.512 458.767 C 499.512 483.155 499.289 57.696 499.289 57.696 Z"
                      />
                    </svg>
                  )}
                </NavLink>
              </li>
              <li className="relative">
                <NavLink
                  to="/account/userlist"
                  className={({ isActive }) =>
                    isActive
                      ? "focus:bg-slate-600 hover:bg-slate-600 flex w-full space-x-2 rounded-md px-3 py-4 font-semibold focus:outline-none"
                      : "focus:bg-slate-600 hover:bg-slate-600 flex w-full space-x-2 rounded-md px-3 py-4 text-gray-300 focus:outline-none"
                  }
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                      />
                    </svg>
                  </span>
                  <span className="">Users</span>
                  {location.pathname === "/account/userlist" && (
                    <svg
                      className="text-slate-200 absolute -right-1 -top-1/2 z-10 hidden h-32 w-8 md:block"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="399.349 57.696 100.163 402.081"
                      width="1em"
                      height="4em"
                    >
                      <path
                        fill="currentColor"
                        d="M 499.289 57.696 C 499.289 171.989 399.349 196.304 399.349 257.333 C 399.349 322.485 499.512 354.485 499.512 458.767 C 499.512 483.155 499.289 57.696 499.289 57.696 Z"
                      />
                    </svg>
                  )}
                </NavLink>
              </li>
              <li className="relative">
                <NavLink
                  to="/account/profile"
                  className={({ isActive }) =>
                    isActive
                      ? "focus:bg-slate-600 hover:bg-slate-600 flex w-full space-x-2 rounded-md px-3 py-4 font-semibold focus:outline-none"
                      : "focus:bg-slate-600 hover:bg-slate-600 flex w-full space-x-2 rounded-md px-3 py-4 text-gray-300 focus:outline-none"
                  }
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                      />
                    </svg>
                  </span>
                  <span className="">Profile</span>
                  {location.pathname === "/account/profile" && (
                    <svg
                      className="text-slate-200 absolute -right-1 -top-1/2 z-10 hidden h-32 w-8 md:block"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="399.349 57.696 100.163 402.081"
                      width="1em"
                      height="4em"
                    >
                      <path
                        fill="currentColor"
                        d="M 499.289 57.696 C 499.289 171.989 399.349 196.304 399.349 257.333 C 399.349 322.485 499.512 354.485 499.512 458.767 C 499.512 483.155 499.289 57.696 499.289 57.696 Z"
                      />
                    </svg>
                  )}
                </NavLink>
              </li>
              <li className="relative">
                <NavLink
                  to="/account/orderhistory"
                  className={({ isActive }) =>
                    isActive
                      ? "focus:bg-slate-600 hover:bg-slate-600 flex w-full space-x-2 rounded-md px-3 py-4 font-semibold focus:outline-none"
                      : "focus:bg-slate-600 hover:bg-slate-600 flex w-full space-x-2 rounded-md px-3 py-4 text-gray-300 focus:outline-none"
                  }
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                      />
                    </svg>
                  </span>
                  <span className="">Order History</span>
                  {location.pathname === "/account/orderhistory" && (
                    <svg
                      className="text-slate-200 absolute -right-1 -top-1/2 z-10 hidden h-32 w-8 md:block"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="399.349 57.696 100.163 402.081"
                      width="1em"
                      height="4em"
                    >
                      <path
                        fill="currentColor"
                        d="M 499.289 57.696 C 499.289 171.989 399.349 196.304 399.349 257.333 C 399.349 322.485 499.512 354.485 499.512 458.767 C 499.512 483.155 499.289 57.696 499.289 57.696 Z"
                      />
                    </svg>
                  )}
                </NavLink>
              </li>
            </ul>
            <div className="my-12 mx-auto space-y-2 w-full ">
              <div className="flex cursor-pointer">
                <img
                  className="h-12 w-12 rounded-full"
                  src="../img/avatar.svg"
                  alt=""
                />
                <div className="ml-3">
                  <p className="font-medium"> {userInfo.name}</p>
                  <p className="text-sm text-gray-300">{userInfo.email}</p>
                </div>
              </div>

              <div className="relative mb-20">
                <button className="focus:bg-slate-600 bg-slate-600 flex justify-center w-full space-x-2 rounded-md px-3 py-4 text-gray-300 focus:outline-none">
                  <span className="">Logout</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="bg-slate-200 flex ">
        <aside className="hidden h-screen lg:block">
          <nav
            aria-label="Sidebar Navigation"
            className=" z-10 flex h-full w-0 flex-col  bg-gray-700 text-white transition-all  md:w-64 lg:w-72"
          >
            <div className="bg-slate-800 py-4">
              <Link to="/" className="flex justify-center">
                <img className="h-11 w-auto" src="../cobaiLight.png" alt="" />
              </Link>
            </div>
            <ul className=" mt-8 space-y-2 peer-checked:w-64 left-0 z-10">
              <li className="relative">
                <NavLink
                  to="/account/overview"
                  className={({ isActive }) =>
                    isActive
                      ? "focus:bg-slate-600 hover:bg-slate-600 flex w-full space-x-2 rounded-md px-10 py-4 font-semibold focus:outline-none"
                      : "focus:bg-slate-600 hover:bg-slate-600 flex w-full space-x-2 rounded-md px-10 py-4 text-gray-300 focus:outline-none"
                  }
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                      />
                    </svg>
                  </span>
                  <span className="">Overview</span>
                  {location.pathname === "/account/overview" && (
                    <svg
                      className="text-slate-200 absolute -right-1 -top-1/2 z-10 hidden h-32 w-8 md:block"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="399.349 57.696 100.163 402.081"
                      width="1em"
                      height="4em"
                    >
                      <path
                        fill="currentColor"
                        d="M 499.289 57.696 C 499.289 171.989 399.349 196.304 399.349 257.333 C 399.349 322.485 499.512 354.485 499.512 458.767 C 499.512 483.155 499.289 57.696 499.289 57.696 Z"
                      />
                    </svg>
                  )}
                </NavLink>
              </li>

              <li className="relative">
                <NavLink
                  to="/account/productlist"
                  className={({ isActive }) =>
                    isActive
                      ? "focus:bg-slate-600 hover:bg-slate-600 flex w-full space-x-2 rounded-md px-10 py-4 font-semibold focus:outline-none"
                      : "focus:bg-slate-600 hover:bg-slate-600 flex w-full space-x-2 rounded-md px-10 py-4 text-gray-300 focus:outline-none"
                  }
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                      />
                    </svg>
                  </span>
                  <span className="">Products</span>
                  {location.pathname === "/account/productlist" && (
                    <svg
                      className="text-slate-200 absolute -right-1 -top-1/2 z-10 hidden h-32 w-8 md:block"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="399.349 57.696 100.163 402.081"
                      width="1em"
                      height="4em"
                    >
                      <path
                        fill="currentColor"
                        d="M 499.289 57.696 C 499.289 171.989 399.349 196.304 399.349 257.333 C 399.349 322.485 499.512 354.485 499.512 458.767 C 499.512 483.155 499.289 57.696 499.289 57.696 Z"
                      />
                    </svg>
                  )}
                </NavLink>
              </li>
              <li className="relative">
                <NavLink
                  to="/account/product/:id/edit"
                  className={({ isActive }) =>
                    isActive
                      ? "focus:bg-slate-600 hover:bg-slate-600 flex w-full space-x-2 rounded-md px-10 py-4 font-semibold focus:outline-none"
                      : "focus:bg-slate-600 hover:bg-slate-600 flex w-full space-x-2 rounded-md px-10 py-4 text-gray-300 focus:outline-none"
                  }
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                      />
                    </svg>
                  </span>
                  <span className="">Create Hotdeal</span>
                  {location.pathname === "/account/product/:id/edit" && (
                    <svg
                      className="text-slate-200 absolute -right-1 -top-1/2 z-10 hidden h-32 w-8 md:block"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="399.349 57.696 100.163 402.081"
                      width="1em"
                      height="4em"
                    >
                      <path
                        fill="currentColor"
                        d="M 499.289 57.696 C 499.289 171.989 399.349 196.304 399.349 257.333 C 399.349 322.485 499.512 354.485 499.512 458.767 C 499.512 483.155 499.289 57.696 499.289 57.696 Z"
                      />
                    </svg>
                  )}
                </NavLink>
              </li>
              <li className="relative">
                <NavLink
                  to="/account/orderlist"
                  className={({ isActive }) =>
                    isActive
                      ? "focus:bg-slate-600 hover:bg-slate-600 flex w-full space-x-2 rounded-md px-10 py-4 font-semibold focus:outline-none"
                      : "focus:bg-slate-600 hover:bg-slate-600 flex w-full space-x-2 rounded-md px-10 py-4 text-gray-300 focus:outline-none"
                  }
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                      />
                    </svg>
                  </span>
                  <span className="">Orders</span>
                  {location.pathname === "/account/orderlist" && (
                    <svg
                      className="text-slate-200 absolute -right-1 -top-1/2 z-10 hidden h-32 w-8 md:block"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="399.349 57.696 100.163 402.081"
                      width="1em"
                      height="4em"
                    >
                      <path
                        fill="currentColor"
                        d="M 499.289 57.696 C 499.289 171.989 399.349 196.304 399.349 257.333 C 399.349 322.485 499.512 354.485 499.512 458.767 C 499.512 483.155 499.289 57.696 499.289 57.696 Z"
                      />
                    </svg>
                  )}
                </NavLink>
              </li>
              <li className="relative">
                <NavLink
                  to="/account/userlist"
                  className={({ isActive }) =>
                    isActive
                      ? "focus:bg-slate-600 hover:bg-slate-600 flex w-full space-x-2 rounded-md px-10 py-4 font-semibold focus:outline-none"
                      : "focus:bg-slate-600 hover:bg-slate-600 flex w-full space-x-2 rounded-md px-10 py-4 text-gray-300 focus:outline-none"
                  }
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                      />
                    </svg>
                  </span>
                  <span className="">Users</span>
                  {location.pathname === "/account/userlist" && (
                    <svg
                      className="text-slate-200 absolute -right-1 -top-1/2 z-10 hidden h-32 w-8 md:block"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="399.349 57.696 100.163 402.081"
                      width="1em"
                      height="4em"
                    >
                      <path
                        fill="currentColor"
                        d="M 499.289 57.696 C 499.289 171.989 399.349 196.304 399.349 257.333 C 399.349 322.485 499.512 354.485 499.512 458.767 C 499.512 483.155 499.289 57.696 499.289 57.696 Z"
                      />
                    </svg>
                  )}
                </NavLink>
              </li>
              <li className="relative">
                <NavLink
                  to="/account/profile"
                  className={({ isActive }) =>
                    isActive
                      ? "focus:bg-slate-600 hover:bg-slate-600 flex w-full space-x-2 rounded-md px-10 py-4 font-semibold focus:outline-none"
                      : "focus:bg-slate-600 hover:bg-slate-600 flex w-full space-x-2 rounded-md px-10 py-4 text-gray-300 focus:outline-none"
                  }
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                      />
                    </svg>
                  </span>
                  <span className="">Profile</span>
                  {location.pathname === "/account/profile" && (
                    <svg
                      className="text-slate-200 absolute -right-1 -top-1/2 z-10 hidden h-32 w-8 md:block"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="399.349 57.696 100.163 402.081"
                      width="1em"
                      height="4em"
                    >
                      <path
                        fill="currentColor"
                        d="M 499.289 57.696 C 499.289 171.989 399.349 196.304 399.349 257.333 C 399.349 322.485 499.512 354.485 499.512 458.767 C 499.512 483.155 499.289 57.696 499.289 57.696 Z"
                      />
                    </svg>
                  )}
                </NavLink>
              </li>
              <li className="relative">
                <NavLink
                  to="/account/orderhistory"
                  className={({ isActive }) =>
                    isActive
                      ? "focus:bg-slate-600 hover:bg-slate-600 flex w-full space-x-2 rounded-md px-10 py-4 font-semibold focus:outline-none"
                      : "focus:bg-slate-600 hover:bg-slate-600 flex w-full space-x-2 rounded-md px-10 py-4 text-gray-300 focus:outline-none"
                  }
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                      />
                    </svg>
                  </span>
                  <span className="">Order History</span>
                  {location.pathname === "/account/orderhistory" && (
                    <svg
                      className="text-slate-200 absolute -right-1 -top-1/2 z-10 hidden h-32 w-8 md:block"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="399.349 57.696 100.163 402.081"
                      width="1em"
                      height="4em"
                    >
                      <path
                        fill="currentColor"
                        d="M 499.289 57.696 C 499.289 171.989 399.349 196.304 399.349 257.333 C 399.349 322.485 499.512 354.485 499.512 458.767 C 499.512 483.155 499.289 57.696 499.289 57.696 Z"
                      />
                    </svg>
                  )}
                </NavLink>
              </li>
            </ul>
            <div className="my-12 px-7 mx-auto space-y-2 w-full ">
              <div className="flex cursor-pointer">
                <img
                  className="h-12 w-12 rounded-full"
                  src="../img/avatar.svg"
                  alt=""
                />
                <div className="ml-3">
                  <p className="font-medium"> {userInfo.name}</p>
                  <p className="text-sm text-gray-300">{userInfo.email}</p>
                </div>
              </div>

              <div className="relative  mb-20">
                <button className="focus:bg-slate-600 bg-slate-600 flex justify-center w-full space-x-2 rounded-md px-3 py-4 text-gray-300 focus:outline-none">
                  <span className="">Logout</span>
                </button>
              </div>
            </div>
          </nav>
        </aside>

        <div className="flex  w-full flex-col">
          <main id="dashboard-main" className="px-2 ">
            <Outlet />

            <div className=" text-center text-[#3B3B8E] font-light mt-5 	 py-8  inset-x-0 bottom-0 ">
              <p className="text-xs">© 2024 Cobai. All rights reserved.</p>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}

export default DashboardLayout;
