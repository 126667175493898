import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { createReview, detailsProduct } from "../../actions/productActions";
import { PRODUCT_REVIEW_CREATE_RESET } from "../../constants/productConstants";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import Rating from "../../components/Rating";
import LoadingBox from "../../components/LoadingBox";
import MessageBox from "../../components/MessageBox";
import { Helmet } from "react-helmet-async";
import Spinner from "../../components/Spinner";
import ReviewLoader from "../../components/ReviewLoader";

const ProductDetails = () => {
  //   const location = useLocation();
  //   const [prevLocation, setPrevLocation] = useState("");
  //   const [productInfo, setProductInfo] = useState([]);

  //   useEffect(() => {
  //     setProductInfo(location.state.item);
  //     setPrevLocation(location.pathname);
  //   }, [location, productInfo]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const addToCartHandler = () => {
    navigate(`/cart/${product._id}?qty=${qty}`);
  };

  const params = useParams();
  const { slug } = params;

  const [qty, setQty] = useState(1);
  const productDetails = useSelector((state) => state.productDetails);
  const { loading, error, product } = productDetails;
  const userSignin = useSelector((state) => state.userSignin);

  const { userInfo } = userSignin;

  const productReviewCreate = useSelector((state) => state.productReviewCreate);
  const {
    loading: loadingReviewCreate,
    error: errorReviewCreate,
    success: successReviewCreate,
  } = productReviewCreate;

  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");

  useEffect(() => {
    if (successReviewCreate) {
      Swal.fire({
        title: "Done!",
        text: "Review Submitted Successfully",
        icon: "success",
        showConfirmButton: false,
        timer: 2000,
      });
      setRating("");
      setComment("");
      dispatch({ type: PRODUCT_REVIEW_CREATE_RESET });
    }
    dispatch(detailsProduct(slug));
  }, [dispatch, slug, successReviewCreate]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (comment && rating) {
      dispatch(createReview(slug, { rating, comment, name: userInfo.name }));
    } else {
      Swal.fire({
        title: "Oops...",
        text: "Please enter comment and rating",
        icon: "error",
        timer: 1500,
      });
    }
  };

  return (
    <div className="w-full mx-auto border-b-[1px] border-b-gray-300">
      {loading ? (
        <LoadingBox />
      ) : error ? (
        <div className="section container">
          <MessageBox variant="text-red-500 bg-red-100 my-1 ">
            {error}{" "}
          </MessageBox>{" "}
        </div>
      ) : (
        <div className="max-w-container mx-auto  px-4 lg:px-20">
          <div class="lg:col-gap-12 xl:col-gap-16 my-10 grid grid-cols-1 gap-12 lg:grid-cols-5 lg:gap-16">
            <div class="lg:col-span-3 lg:row-end-1">
              <div class="lg:flex lg:items-start">
                <div class="lg:order-2 lg:ml-5">
                  <div class="max-w-xl overflow-hidden rounded-lg">
                    <img
                      class="h-[20rem] w-full object-cover"
                      src={product.image}
                      alt={product.name}
                    />
                  </div>
                </div>
                {/* <Helmet>
                  <title>{slug} </title>
                </Helmet> */}
                <div class="mt-2 w-full lg:order-1 lg:w-32 lg:flex-shrink-0">
                  <div class="flex flex-row items-start lg:flex-col">
                    <button
                      type="button"
                      class="flex-0 aspect-square mb-3 h-20 overflow-hidden rounded-lg border-2 border-gray-900 text-center"
                    >
                      <img
                        class="h-full w-full object-cover"
                        src={product.image}
                        alt={product.name}
                      />
                    </button>
                    <button
                      type="button"
                      class="flex-0 aspect-square mb-3 h-20 overflow-hidden rounded-lg border-2 border-transparent text-center"
                    >
                      <img
                        class="h-full w-full object-cover"
                        src={product.image}
                        alt={product.name}
                      />
                    </button>
                    <button
                      type="button"
                      class="flex-0 aspect-square mb-3 h-20 overflow-hidden rounded-lg border-2 border-transparent text-center"
                    >
                      <img
                        class="h-full w-full object-cover"
                        src={product.image}
                        alt={product.name}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="lg:col-span-2 lg:row-span-2 lg:row-end-2">
              <h1 class="sm: text-2xl font-bold text-gray-900 sm:text-3xl">
                {product.name}
              </h1>

              <Rating
                size1={"w-5 md:w-6 h-7"}
                size2={"w-4 md:w-7 h-5"}
                rating={product.rating}
                numReviews={product.numReviews}
              />

              <p className="text-base text-gray-600 mt-2">
                {product.description}
              </p>

              <p className="font-normal text-sm mt-2 text-gray-600">
                <span className="text-base font-medium text-gray-800">
                  {" "}
                  Categories:
                </span>{" "}
                {product.category}
              </p>

              {product.countInStock > 0 && (
                <>
                  <h2 class="mt-8 text-base text-gray-900">Quantity</h2>
                  <div className="flex gap-3 mt-1 ">
                    <div className="flex items-center border-gray-100">
                      <button
                        type="button"
                        disabled={qty === 1}
                        onClick={(e) => setQty((e.target.value = qty - 1))}
                        className="cursor-pointer rounded-md bg-[#3e932b] text-base sm:text-2xl text-white px-2 sm:py-1 sm:px-3.5 duration-100 hover:bg-[#3e932b]/50 hover:text-blue-50"
                      >
                        -
                      </button>
                      <input
                        className="h6 sm:h-8 w-6 sm:w-8 bg-white text-center text-xs outline-none"
                        type="text"
                        value={qty}
                      />
                      <button
                        type="button"
                        disabled={qty === product.countInStock}
                        onClick={(e) => setQty((e.target.value = qty + 1))}
                        className="cursor-pointer rounded-md bg-[#3e932b] text-base sm:text-2xl text-white px-1.5 sm:py-1 sm:px-4 duration-100 hover:bg-[#3e932b]/50 hover:text-blue-50"
                      >
                        +
                      </button>
                    </div>
                  </div>
                </>
              )}
              <div class="mt-5 flex flex-col items-center justify-between space-y-4 border-t border-b py-4 sm:flex-row sm:space-y-0">
                <div class="flex items-end">
                  <h1 class="text-3xl font-bold">
                    {" "}
                    ₦{product.price.toLocaleString()}
                  </h1>
                </div>

                {product.countInStock > 0 && (
                  <>
                    <button
                      type="button"
                      onClick={addToCartHandler}
                      class="inline-flex items-center justify-center rounded-md border-2 border-transparent bg-gray-900 bg-none px-12 py-3 text-center text-base font-bold text-white transition-all duration-200 ease-in-out focus:shadow hover:bg-gray-800"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="shrink-0 mr-3 h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                        />
                      </svg>
                      Add to cart
                    </button>{" "}
                  </>
                )}
              </div>

              <div className="flex gap-2 my-4 items-center ">
                <span className="font-light text-xs text-[#1b5921] underline decoration-green-700 underline-offset-4 decoration-from-font">
                  Share this product with friends:
                </span>
                <div className="items-center lg:mb-0  gap-2 flex py-1">
                  {/* Twitter */}
                  <a
                    href={`https://twitter.com/intent/tweet/?text=${product.description}. Checkout the link below to order ${product.name} at Cobai &url=https%3A%2F%2Fcobai.africa%2Fproduct%2F${product.slug}`}
                    target="_blank"
                    rel="noopener"
                    className="bg-[#3e932b] text-white  font-normal h-8 w-8  py-1.5 items-center justify-center align-center rounded-full outline-none focus:outline-none  "
                  >
                    <svg
                      className="w-8 h-5"
                      fill="#f3f0f0"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"></path>
                    </svg>
                  </a>
                  {/* whatsapp */}
                  <a
                    href={`https://api.whatsapp.com/send?text=${product.description}. Checkout the link below to order ${product.name} at Cobai %20https%3A%2F%2Fcobai.africa%2Fproduct%2F${product.slug}`}
                    target="_blank"
                    rel="noopener"
                    className="bg-[#3e932b] text-white  font-normal h-8 w-8  py-1.5 items-center justify-center align-center rounded-full outline-none focus:outline-none  "
                  >
                    <svg
                      className="w-8 h-5"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#f3f0f0"
                        d="M12.001 2c5.523 0 10 4.477 10 10s-4.477 10-10 10a9.954 9.954 0 0 1-5.03-1.355L2.005 22l1.352-4.968A9.953 9.953 0 0 1 2.001 12c0-5.523 4.477-10 10-10ZM8.593 7.3l-.2.008a.961.961 0 0 0-.372.1a1.293 1.293 0 0 0-.294.228c-.12.113-.188.211-.261.306A2.73 2.73 0 0 0 6.9 9.62c.002.49.13.967.33 1.413c.409.902 1.082 1.857 1.97 2.742c.214.213.424.427.65.626a9.448 9.448 0 0 0 3.84 2.046l.568.087c.185.01.37-.004.556-.013a1.99 1.99 0 0 0 .833-.231a4.83 4.83 0 0 0 .383-.22s.043-.028.125-.09c.135-.1.218-.171.33-.288c.083-.086.155-.187.21-.302c.078-.163.156-.474.188-.733c.024-.198.017-.306.014-.373c-.004-.107-.093-.218-.19-.265l-.582-.261s-.87-.379-1.402-.621a.497.497 0 0 0-.176-.041a.482.482 0 0 0-.378.127c-.005-.002-.072.055-.795.931a.35.35 0 0 1-.368.13a1.43 1.43 0 0 1-.191-.066c-.124-.052-.167-.072-.252-.108a6.025 6.025 0 0 1-1.575-1.003c-.126-.11-.243-.23-.363-.346a6.297 6.297 0 0 1-1.02-1.268l-.059-.095a.923.923 0 0 1-.102-.205c-.038-.147.061-.265.061-.265s.243-.266.356-.41c.11-.14.203-.276.263-.373c.118-.19.155-.385.093-.536c-.28-.684-.57-1.365-.868-2.041c-.059-.134-.234-.23-.393-.249c-.054-.006-.108-.012-.162-.016a3.385 3.385 0 0 0-.403.004l.201-.008Z"
                      />
                    </svg>
                  </a>
                  {/* LinkedIn */}
                  <a
                    href={`https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Fcobai.africa%2Fproduct%2F${product.slug}`}
                    target="_blank"
                    rel="noopener"
                    className="bg-[#3e932b] text-[#f3f0f0] font-normal h-8 w-8  py-1.5 items-center justify-center align-center rounded-full outline-none focus:outline-none  "
                  >
                    <svg
                      className="w-8 h-4"
                      width="38"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0 1.99134C0 1.41413 0.202708 0.937939 0.608108 0.56277C1.01351 0.187584 1.54055 0 2.18919 0C2.82626 0 3.34169 0.184693 3.73552 0.554112C4.14092 0.935065 4.34363 1.43145 4.34363 2.04329C4.34363 2.5974 4.14672 3.05915 3.7529 3.42857C3.3475 3.80952 2.81467 4 2.15444 4H2.13707C1.49999 4 0.984562 3.80952 0.590734 3.42857C0.196905 3.04762 0 2.56854 0 1.99134ZM0.225869 17.1429V5.57576H4.08301V17.1429H0.225869ZM6.22008 17.1429H10.0772V10.684C10.0772 10.2799 10.1236 9.96824 10.2162 9.74891C10.3784 9.35641 10.6245 9.02452 10.9546 8.75324C11.2847 8.48195 11.6988 8.34632 12.1969 8.34632C13.4942 8.34632 14.1429 9.21788 14.1429 10.961V17.1429H18V10.5108C18 8.8023 17.5946 7.50649 16.7838 6.62337C15.973 5.74026 14.9015 5.2987 13.5695 5.2987C12.0753 5.2987 10.9112 5.93939 10.0772 7.22078V7.25541H10.0598L10.0772 7.22078V5.57576H6.22008C6.24324 5.94516 6.25483 7.09378 6.25483 9.02164C6.25483 10.9495 6.24324 13.6565 6.22008 17.1429Z"
                        fill="#f3f0f0"
                      />
                    </svg>
                  </a>
                  {/* Facebook */}
                  <a
                    href={`https://facebook.com/sharer/sharer.php?u=https%3A%2F%2Fcobai.africa%2Fproduct%2F${product.slug}`}
                    target="_blank"
                    rel="noopener"
                    className="bg-[#3e932b] text-white  font-normal h-8 w-8  py-1.5 items-center justify-center align-center rounded-full outline-none focus:outline-none  "
                  >
                    <svg
                      className="w-8 h-5"
                      width="38"
                      height="18"
                      viewBox="0 0 10 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.37296 18V9.78936H9.26765L9.70104 6.5895H6.37288V4.54653C6.37288 3.6201 6.64305 2.98879 8.0385 2.98879L9.81819 2.98799V0.126072C9.51038 0.0871459 8.45386 0 7.22488 0C4.65886 0 2.90212 1.49118 2.90212 4.22972V6.5895H0V9.78936H2.90212V17.9999H6.37296V18Z"
                        fill="#f3f0f0"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>

            <div class="lg:col-span-3">
              <div class="border-b border-gray-300">
                <nav class="flex gap-4">
                  <a
                    title=""
                    class="inline-flex items-center border-b-2 border-transparent py-4 text-sm font-medium text-gray-600"
                  >
                    Reviews
                    <span class="ml-2 block rounded-full bg-gray-500 px-2 py-px text-xs font-bold text-gray-100">
                      {product.numReviews}
                    </span>
                  </a>
                </nav>
              </div>

              <div class="mt-3 flow-root sm:mt-5">
                {product.reviews.length === 0 && (
                  <div className="py-3 px-4 rounded-md bg-[#D7DADA]/20 ">
                    <MessageBox>No Reviews</MessageBox>
                  </div>
                )}

                {product.reviews.map((review) => (
                  <div key={review._id} className="border-b p-1 lg:p-3">
                    <div className="py-3 px-4 rounded-md bg-[#D7DADA]/20 ">
                      <div className="flex items-center justify-between">
                        <Rating
                          rating={review.rating}
                          size1={"w-3.5 md:w-5 h-6"}
                          size2={"w-3 md:w-6 h-5"}
                        />
                        <p className="lg:hidden text-xs  font-normal text-gray-600 ">
                          {review.createdAt.substring(0, 10)}
                        </p>
                      </div>

                      <h2 className="text-xs  font-normal text-gray-900  leading-5 ">
                        {review.comment}
                      </h2>

                      <div className="flex py-3  items-center justify-between">
                        <div className="flex items-center gap-x-2">
                          <div className="bg-[#D9D9D9] w-6 h-6  lg:w-8 lg:h-8 p-1 lg:p-1.5 text-xs lg:text-sm rounded-full">
                            {review.name.substring(0, 1)}
                          </div>
                          <div>
                            <h2 className="flex text-xs sm:text-sm font-medium text-gray-800  ">
                              {review.name}
                            </h2>
                            <p className="hidden lg:block text-xs -mt-1 font-normal text-gray-600 ">
                              {review.createdAt.substring(0, 10)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                {loadingReviewCreate && <ReviewLoader />}

                {userInfo && (
                  <form onSubmit={submitHandler} class="w-full p-5  md:px-12 ">
                    <div class="flex">
                      <h1 class="font-bold text-xl">Write a customer review</h1>
                    </div>

                    <div className=" w-full mt-2">
                      <div class="relative">
                        <select
                          value={rating}
                          onChange={(e) => setRating(e.target.value)}
                          class="bg-gray-100 appearance-none text-gray-400 font-semibold text-sm rounded-lg  block w-full p-3 "
                        >
                          <option selected>Select Rating</option>
                          <option value="1">1 - Poor</option>
                          <option value="2">2 - Fair</option>
                          <option value="3">3 - Good</option>
                          <option value="4">4 - Very Good</option>
                          <option value="5">5 - Excelent</option>
                        </select>
                        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                          <svg
                            class="fill-current h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div class="mb-2">
                      <textarea
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        placeholder="Comment"
                        required
                        class="w-full h-16 bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                      ></textarea>
                    </div>
                    <button class="w-[7rem] text-sm font-bold items-center text-center rounded-lg bg-[#4cc5d2] px-2 py-0.5 sm:py-1.5 text-white duration-100 hover:bg-gray-400">
                      {loadingReviewCreate ? <Spinner /> : "Submit"}
                    </button>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductDetails;
